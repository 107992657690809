import React, { useState, useEffect } from "react";
import { Stack, Typography, Box, Grid } from "@mui/material";
import { StyledTextBox, StyledSelectTextBox } from "../../components/TextBox";
import { ContainedButton } from "../../components/Buttons";
import Badge from "@mui/material/Badge";
import { SmallAvatar } from "../../components/Badge";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import {
  GetAdminData,
  UpdateAdminData,
} from "../../services/settings.services";
import validator from "validator";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import PasswordSettings from "./PasswordSettings";

const AvatarURL =
  "https://unicorn-dev-assests.s3.amazonaws.com/admin-profile/avatar/";

export default function Settings() {
  const [values, setValues] = useState({
    // active: true,
    // backOfz: true,
    currency: "USD ($)",
    email: "",
    // encryptedId: "",
    firstName: "",
    imageUrlPath: "",
    lastName: "",
    timezone: "UTC-4 New York, United States",
    username: "",
    accountType: "super Administrator",
  });

  const [errorEmail, setErrorEmail] = useState({ error: false, message: "" });
  const [errorFirstName, setErrorFirstName] = useState({
    error: false,
    message: "",
  });
  const [errorLastName, setErrorLastName] = useState({
    error: false,
    message: "",
  });

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await GetAdminData().then((data) => {
          // console.log(data);
          setValues({
            // active: true,
            // backOfz: true,
            currency: data.currency,
            email: data.email,
            // encryptedId: data.encryptedId,
            firstName: data.firstName,
            imageUrlPath: data.imageUrlPath,
            lastName: data.lastName,
            timezone: data.timezone,
            username: data.username,
            accountType: "super Administrator",
          });
        });
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  const handleUpdateData = (event) => {
    event.preventDefault();
    let error = false;

    if (values.firstName === "") {
      setErrorFirstName({ error: true, message: "Please give a First name" });
      error = true;
    } else {
      setErrorFirstName({ error: false, message: "" });
      error = false;
    }

    if (values.lastName === "") {
      setErrorLastName({ error: true, message: "Please give a Last name" });
      error = true;
    } else {
      setErrorLastName({ error: false, message: "" });
      error = false;
    }

    if (values.email === "") {
      setErrorEmail({ error: true, message: "Please give a Email" });
      error = true;
    } else {
      setErrorEmail({ error: false, message: "" });
      error = false;
    }

    if (validator.isEmail(values.email)) {
      setErrorEmail({ error: false, message: "" });
      error = false;
    } else {
      setErrorEmail({ error: true, message: "Please give a valid Email" });
      error = true;
    }

    if (!error) {
      UpdateAdminData(values).then((response) => {
        console.log(response);
        if (response.ok) {
          setUpdated(true);
          console.log("text" + updated);
        }
      });
    }
  };

  // Popper functionality
  // const [profile, setProfile] = useState("");
  //   console.log(profile);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openF, setOpenF] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenF((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  // Clickover function
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pl: 2 }}
      >
        <Typography variant="h3">Account Settings</Typography>
      </Stack>
      <br></br>

      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          p: 5,
        }}
      >
        <Typography variant="h4" sx={{ color: "primary.Primary_Blue" }}>
          Personal Information
        </Typography>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                First Name
              </Typography>
            </Grid>
            <Grid xs={9}>
              <StyledTextBox
                error={errorFirstName.error}
                fullWidth
                value={values.firstName}
                onChange={(e) =>
                  setValues({ ...values, firstName: e.target.value })
                }
              />
              {errorFirstName.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setErrorFirstName({ error: false, message: "" })
                    }
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {errorFirstName.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                Last Name
              </Typography>
            </Grid>
            <Grid xs={9}>
              <StyledTextBox
                error={errorLastName.error}
                fullWidth
                value={values.lastName}
                onChange={(e) =>
                  setValues({ ...values, lastName: e.target.value })
                }
              />
              {errorLastName.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setErrorLastName({ error: false, message: "" })
                    }
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {errorLastName.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                Email
              </Typography>
            </Grid>
            <Grid xs={9}>
              <StyledTextBox
                error={errorEmail.error}
                fullWidth
                value={values.email}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
              />
              {errorEmail.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() => setErrorEmail({ error: false, message: "" })}
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {errorEmail.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                Timezone
              </Typography>
            </Grid>
            <Grid xs={9}>
              <StyledSelectTextBox
                disabled
                fullWidth
                select
                value={values.timezone}
                onChange={(e) =>
                  setValues({ ...values, timezone: e.target.value })
                }
              >
                <MenuItem value="UTC-4 New York, United States">
                  UTC-4 New York, United States{" "}
                </MenuItem>
              </StyledSelectTextBox>
            </Grid>
          </Grid>
        </Grid>

        <br></br>

        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                Account Type
              </Typography>
            </Grid>
            {/* account type doesn't have a value in swagger  */}
            <Grid xs={9}>
              <StyledTextBox
                fullWidth
                placeholder="Super Administrator"
                disabled
              />
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                Currency
              </Typography>
            </Grid>
            <Grid xs={9}>
              <StyledSelectTextBox
                disabled
                fullWidth
                select
                value={values.currency}
                onChange={(e) =>
                  setValues({ ...values, currency: e.target.value })
                }
              >
                <MenuItem value="USD ($)">USD ($)</MenuItem>
              </StyledSelectTextBox>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid
            xs={5}
            container
            direction="row"
            alignItems="center"
            sx={{ pt: 1 }}
          >
            <Grid xs={3}>
              <Typography
                variant="body2"
                color="neutral.letter_Black"
                sx={{ fontWeight: "bold" }}
              >
                Profile Picture
              </Typography>
            </Grid>

            <Grid xs={5} container direction="row" alignItems="center">
              <Grid
                item
                container
                xs={6}
                alignItems="flex-start"
                direction="column"
              >
                <Grid item>
                  <Badge onClick={handleClick("right")}>
                    <Badge
                      onClick={handleClickOpen}
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                        height: "4em",
                        width: "4em",
                        paddingRight: 2,
                      }}
                      badgeContent={
                        //   <SmallAvatar alt="Remy Sharp" src={EditIcon} />
                        <SmallAvatar sx={{ width: "1,5em", height: "1.5em" }}>
                          <EditIcon />
                        </SmallAvatar>
                      }
                    >
                      <Avatar
                        src={values.imageUrlPath}
                        sx={{ width: "4.8em", height: "4.8em" }}
                      />
                    </Badge>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={9}>
              <Box>
                <Popper open={openF} anchorEl={anchorEl} placement="right">
                  <Paper sx={{ padding: 2 }}>
                    <Stack direction="row" spacing={8}>
                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}userone.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}userone.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>

                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}usertwo.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}usertwo.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={`${AvatarURL}usertwo.svg`}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>

                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}userthree.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}userthree.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>
                    </Stack>

                    <Stack direction="row" spacing={8} sx={{ paddingTop: 2 }}>
                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}userfour.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}userfour.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>

                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}userfive.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}userfive.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>

                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}usersix.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}usersix.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>
                    </Stack>

                    <Stack direction="row" spacing={8} sx={{ paddingTop: 2 }}>
                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}userseven.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}userseven.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>

                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}usereight.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}usereight.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>

                      <Grid xs={3}>
                        <Badge
                          onClick={(e) =>
                            setValues({
                              ...values,
                              imageUrlPath: `${AvatarURL}usernine.svg`,
                            })
                          }
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            values.imageUrlPath ===
                            `${AvatarURL}usernine.svg` ? (
                              <SmallAvatar>
                                <CheckCircleOutlineOutlinedIcon />
                              </SmallAvatar>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={values.imageUrlPath}
                            sx={{ width: "4.8em", height: "4.8em" }}
                          />
                        </Badge>
                      </Grid>
                    </Stack>
                  </Paper>
                </Popper>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <br></br>
        {/* <Grid xs={6} container justifyContent="flex-start" alignItems="center" direction="row">
          
            <Grid xs={5}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" >
              <ContainedButton fullWidth sx={{ height: "3em" }} 
              onClick = {(event) => handleUpdateData(event)}>
                Update&nbsp;Now
              </ContainedButton>
              {!updated ?<Typography sx={{color:"#00AD47",display: 'inline-block'}} ><CheckCircleOutlineOutlinedIcon sx={{fontSize: 18,alignItems:"center"}}/>Changes have been successfully saved!</Typography> : ""}
              </Stack>
            </Grid>
        </Grid> */}

        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={3}>
              <ContainedButton
                fullWidth
                sx={{ height: "3em", p: 2 }}
                onClick={(event) => handleUpdateData(event)}
              >
                Update&nbsp;Now
              </ContainedButton>
            </Grid>
            <Grid xs={1} />
            <Grid xs={8} alignItems="center" justifyContent="center">
              {updated ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ fontSize: 20, color: "#00AD47", fontWeight: "600" }}
                  />
                  <Typography variant="body2" color="#00AD47" fontWeight="600">
                    Changes have been successfully saved!
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <br></br>

      <PasswordSettings />
    </div>
  );
}
