import React from "react";
import {
  Typography,
  Grid,
  TableBody,
  TableRow,
  Table,
  Stack,
} from "@mui/material";
import { TableCellSummary } from "../../components/Table";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

export default function RecentTransfers() {
  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Stack sx={{ p: 2, mb: 1 }}>
          <Typography variant="h4" color="primary.Primary_Blue">
            Recent Transfers
          </Typography>
        </Stack>
        <Stack sx={{ maxHeight: '28em', overflow: 'auto' }}>
        <Table aria-label="simple table" size="small" sx={{ mb: 2 }} >
          <TableBody>
            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  01 Feb,2022
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  Ref:00012
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "warning.Warning_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "warning.Warning_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "danger.Danger_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>
            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>


            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>

            <TableRow>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  01 Feb,2022
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  05.30pm
                </Typography>
              </TableCellSummary>
              <TableCellSummary align="left" variant="body2">
                <Typography variant="body2" color="neutral.Gray_Dark">
                  
                  Ref:00012
                </Typography>
              </TableCellSummary>

              <TableCellSummary align="center" variant="body2">
                <RadioButtonCheckedOutlinedIcon
                  sx={{ color: "success.Success_Dk" }}
                />
              </TableCellSummary>
              <TableCellSummary align="right" variant="body2">
                <Typography variant="body2" color="neutral.letter_Black">
                  
                  $2,685.50
                </Typography>
              </TableCellSummary>
            </TableRow>
          </TableBody>
        </Table>
        </Stack>
      </Grid>
    </div>
  );
}
