import * as React from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditClientInformation from "./EditClientInformation";
import BusinessInformation from "./BusinessInformation";
import BankInformation from "./BankInformation";
import {
  ContainedErrorButton,
  RoundEdgeContainedButton,
} from "../../../components/Buttons";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";
import Modal from "@mui/material/Modal";
// import Warning from "../src/assets/warning";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  StyledTextArea,
  StyledTextBox,
} from "../../../components/TextBox";
import { ContainedAshButton } from "../../../components/Buttons";

export default function EditInformation() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 640,
    height: 680,
    bgcolor: "background.paper",
    borderTop: "10px solid #F33535",
    // boxShadow: 24,
    p: 4,
    boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
    borderRadius: "8px",
  };

  const [value, setValue] = React.useState("1");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <NavLink
            to="/client-admin"
            state={{ pageTitle: "Client Admin" }}
          >
            <IconButton>
              <ArrowBackIosIcon />
            </IconButton>
          </NavLink>
          <Typography variant="h3">Edit Information</Typography>
        </Stack>

        <RoundEdgeContainedButton
          variant="contained"
          startIcon={<ControlPointIcon />}
        >
          New Client
        </RoundEdgeContainedButton>
      </Stack>
      <br></br>

      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
        }}
        pt={1}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "#CAD7F4" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Client Information"
                value="1"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Business Information"
                value="2"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Bank Information"
                value="3"
                sx={{ textTransform: "capitalize" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EditClientInformation />
          </TabPanel>
          <TabPanel value="2">
            <BusinessInformation />
          </TabPanel>
          <TabPanel value="3">
            <BankInformation />
          </TabPanel>
        </TabContext>
      </Box>
      <br></br>

      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          border: "2px solid #F33535",
          display: value === "1" ? "" : "none",
        }}
        pl={5}
        pr={5}
        pb={5}
      >
        <Stack alignItems="center" direction="row" pt={2}>
          <Typography variant="h4" color="neutral.letter_Black">
            Account Deactivation
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2} pt={5}>
          <WarningAmberIcon sx={{ color: "#F33535" }} />
          <Typography variant="body1" color="neutral.letter_Black">
            Permenently deactivate account
          </Typography>
        </Stack>

        <Stack alignItems="center" direction="row" spacing={2} pt={5} pl={5}>
          <Typography variant="body2" color="neutral.letter_Black">
            This action will make client ‘s account permenently dissable and you
            will not be able to re-register the same user id and user name.
            Also,
          </Typography>
        </Stack>

        <Stack spacing={2} pt={3} pl={10}>
          <Typography variant="body2" color="neutral.letter_Black">
            * Permenently remove business details.
          </Typography>
          <Typography variant="body2" color="neutral.letter_Black">
            * Transfer all remaining funds to client’s account.
          </Typography>
          <Typography variant="body2" color="neutral.letter_Black">
            * This action is not reversible.
          </Typography>
          <Typography variant="body2" color="neutral.letter_Black">
            * Lorem ipsum cil si ti Lorem ipsum cil si ti Lorem ipsum cil si ti
          </Typography>
        </Stack>
        <br></br>
        <ContainedErrorButton variant="contained" onClick={handleOpen}>
          Deactivate Account
        </ContainedErrorButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderTop: 2 }}
        >
          <Box sx={style}>
            <WarningAmberOutlinedIcon
              sx={{ color: "#F33535", fontSize: 60, marginLeft: "45%" }}
            ></WarningAmberOutlinedIcon>

            <Typography
              variant="h3"
              textAlign="center"
              sx={{ marginBottom: "2%" }}
            >
              Deactivate Account
            </Typography>
            <Typography
              variant="body2"
              justifyItems="center"
              textAlign="justify"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non
              tortor eget non, bibendum nunc, lorem lorem nullam duis. Egestas
              ultrices in nibh eget volutpat, placerat malesuada. Arcu sed
              montes, posuere sed a sit nullam ultricies. Sed id imperdiet
              turpis sit. Et arcu potenti proin arcu sollicitudin. Ultricies sem
              nisl.
            </Typography>
            <Stack spacing={2} pt={2}>
              <Typography variant="body2" color="neutral.letter_Black">
                * Permenently remove business details.
              </Typography>
              <Typography variant="body2" color="neutral.letter_Black">
                * Transfer all remaining funds to client’s account.
              </Typography>
              <Typography variant="body2" color="neutral.letter_Black">
                * This action is not reversible.
              </Typography>
            </Stack>

            <Typography sx={{ marginBottom: "1.8%", marginTop: "2%" }}>
              Note to client<span style={{ color: "#EA5455" }}>*</span>
            </Typography>
            <StyledTextArea style={{ width: "100%" }}
              multiline
              placeholder="Your text here"
              maxRows={4}
            />
            <Typography
              variant="body1"
              sx={{ marginBottom: "2%", marginTop: "3%" }}
            >
              Type “Deactivate Account” to confirm.
            </Typography>
            <StyledTextBox
              placeholder="Deactivate account"
              sx={{ width: "256px", height: "3em" }}
            />
            <Stack direction="row" spacing={2} sx={{ marginTop: "5%" }}>
              <ContainedErrorButton variant="contained">
                confirm
              </ContainedErrorButton>
              <ContainedAshButton>Cancel</ContainedAshButton>
            </Stack>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
