import {
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { ContainedSearchButton } from "../../../components/Buttons";
import { StyledSelectTextBox, StyledTextBox } from "../../../components/TextBox";
import validator from "validator";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { Stack } from "@mui/system";

export default function CreateClientInformation() {

  
  const [values, setValues] = useState({
    address: "",
    city: "",
    clientType: "SELLER",
    contactNo: "",
    country: "United States",
    currency: "USD ($)",
    email: "",
    firstName: "",
    imageUrlPath: "no images",
    lastName: "",
    state: "",
    timezone: "UTC-4 New York, United States",
    username: "",
    zipCode: "",
    nicPassportNo: "",
    secondaryContactNo: "",
  })


  const [errors, setErrors] = useState({
    address: {error: false, message: ''},
    city: {error: false, message: ''},
    clientType: {error: false, message: ''},
    contactNo: {error: false, message: ''},
    email: {error: false, message: ''},
    firstName: {error: false, message: ''},
    lastName: {error: false, message: ''},
    state: {error: false, message: ''},
    username: {error: false, message: ''},
    zipCode: {error: false, message: ''},
    nicPassportNo: {error: false, message: ''},
  })
  


  useEffect(() => {
    if(localStorage.getItem('clientInfo')){
      setValues(JSON.parse(localStorage.getItem('clientInfo')))
    }
}, [])
  
  const handleSubmit = (e) => {
    e.preventDefault()
    let error = false;

    const requiredFields = Object.keys(errors);

    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (values[field] === "") {
        let errorMessage;
        switch (field) {
          case field:
            errorMessage = "This field is required";
            break;
          default:
            errorMessage = "This field is required";
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { error: true, message: errorMessage },
        }));
        error = true;
      }else if (values[field] !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { error: false, message: '' },
        }));
        error = false
      }
    }

    if(!validator.isEmail(values.email)){
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: { error: true, message: 'Enter a valid email' },
      }));
      error = true;
    }




    if(!error){
      localStorage.setItem('clientInfo', JSON.stringify(values))
      localStorage.setItem('newClentStep', 2)
      window.location = "/client-information/new-client"
    }
   




  }


  return (
    <div>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Client Type
            </Typography>
          </Grid>
          <Grid xs={9}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={values.clientType}
              >
                <FormControlLabel
                  value="SELLER"
                  control={<Radio size="small" />}
                  label="Seller"
                  sx={{color:"neutral.letter_Black"}}
                  onClick={() => setValues({...values, clientType: "SELLER"})}
                />
                <FormControlLabel
                  value="PARTNER"
                  control={<Radio size="small" />}
                  label="Partner"
                  onClick={() => setValues({...values, clientType: "PARTNER"})}
                />
              </RadioGroup>
            </FormControl>

            {errors.clientType.error ? (
               <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, clientType: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.clientType.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
      </Grid>

      <br></br>
      <Grid container justifyContent="flex-start">
        
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Client name
            </Typography>
          </Grid>
          <Grid xs={9}>
            
            <StyledTextBox fullWidth value={values.username} onChange={(e) => setValues({...values, username: e.target.value})} error={errors.username.error}/>
            {errors.username.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, username: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.username.message}</Typography>
               </Stack>
            ):''}
           
            
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              First Name
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.firstName.error} fullWidth value={values.firstName} onChange={(e) => setValues({...values, firstName: e.target.value})} />
            {errors.firstName.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, firstName: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.firstName.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
      <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Last Name
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.lastName.error} fullWidth value={values.lastName} onChange={(e) => setValues({...values, lastName: e.target.value})} />
            {errors.lastName.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, lastName: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.lastName.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            Email
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.email.error} fullWidth value={values.email} onChange={(e) => setValues({...values, email: e.target.value})}/>
            {errors.email.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, email: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.email.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Address
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.address.error} fullWidth value={values.address} onChange={(e) => setValues({...values, address: e.target.value})} />
            {errors.address.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, address: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.address.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            City
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.city.error} fullWidth value={values.city} onChange={(e) => setValues({...values, city: e.target.value})} />
            {errors.city.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, city: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.city.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            State
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.state.error} fullWidth value={values.state} onChange={(e) => setValues({...values, state: e.target.value})} />
            {errors.state.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, state: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.state.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            Zip Code
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.zipCode.error} fullWidth value={values.zipCode} onChange={(e) => setValues({...values, zipCode: e.target.value})} />
            {errors.zipCode.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, zipCode: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.zipCode.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Country
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledSelectTextBox
            fullWidth
            value={values.country}
            onChange={(e) => setValues({...values, country: e.target.value})}
            >
              <MenuItem value="United States">United States</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
            </StyledSelectTextBox>
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            Currency
            </Typography>
          </Grid>
          <Grid xs={9}>
          <StyledSelectTextBox
            fullWidth
            value={values.currency}
            onChange={(e) => setValues({...values, currency: e.target.value})}
            >
              <MenuItem value="USD ($)">USD ($)</MenuItem>
              <MenuItem value="CANADA ($)">CANADA ($)</MenuItem>
            </StyledSelectTextBox>
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            Contact
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.contactNo.error} fullWidth value={values.contactNo} onChange={(e) => setValues({...values, contactNo: e.target.value})}/>
            {errors.contactNo.error ? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, contactNo: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.contactNo.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            Timezone
            </Typography>
          </Grid>
          <Grid xs={9}>
          <StyledSelectTextBox
            fullWidth
            select
            value={values.timezone}
            onChange={(e) => setValues({...values, timezone: e.target.value})}
            >
              <MenuItem value="UTC-4 New York, United States">UTC-4 New York, United States </MenuItem>
            </StyledSelectTextBox>
          </Grid>
        </Grid>
      </Grid>

      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            NIC/Passport
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox error={errors.nicPassportNo.error} fullWidth value={values.nicPassportNo} onChange={(e) => setValues({...values, nicPassportNo: e.target.value})}/>
            {errors.nicPassportNo.error? (
               <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, nicPassportNo: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.nicPassportNo.message}</Typography>
               </Stack>
            ):''}
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
            Secondary Contact
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value={values.secondaryContactNo} onChange={(e) => setValues({...values, secondaryContactNo: e.target.value})}/>
          </Grid>
        </Grid>
      </Grid>
   
      <br></br>
      <ContainedSearchButton variant="contained" onClick={(e) => handleSubmit(e)}>
      Continue
      </ContainedSearchButton>

      <br></br>
    </div>
  );
}
