import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ContainerBox } from "../../components/Box";

export default function Chart(props) {
  const chartOptions = {
    title: false,
    chart: {
      type: props.chartType,
    },
    xAxis: {
      title: {
        text: "Purchase Date",
      },
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug"],
    },
    yAxis: {
      title: {
        text: "Total Sales",
      },
      //   tickInterval: 50,
      //   labels: {
      //     formatter: function () {
      //       return "$" + this.value;
      //     },
      //   },
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[3]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[3])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    series: [
      {
        name: 'Actual Withdrawal Amount',
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5],
        color: props.color,
      },
      {
        name: 'Project Withdrawal Request',
        data: [40.9, 80.5, 50.4, 28.2, 150.0, 80.0, 130.6, 108.5],
        color: 'grey',
      },
    ],
    credits: {
      enabled: false,
    }, // The water mark removal place
  };

  return (
    <>
        <ContainerBox>
          <HighchartsReact options={chartOptions} highcharts={Highcharts} />
        </ContainerBox>
    </>
  );
}
