import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const LoadingBox = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "50vh",
}));

export const ContainerBox = styled(Box)(({ theme }) => ({
	background: "#FFFFFF",
	boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
	borderRadius: "18px",
	padding: 15,
	height: '100%'
}));