import * as React from "react";
import { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Stack,
  InputAdornment,
  IconButton,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  TableBody,
  TableRow,
  Table,
} from "@mui/material";
import { StyledSelectTextBox, StyledTextBox } from "../../components/TextBox";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import { TableCellSummary } from "../../components/Table";
import { GetTransactionsSummary } from "../../services/transactions.services";
import { formatCurrency } from "../../utils/utilityFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingBox } from "../../components/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const marketPlacesNames = ["All", "Amazon", "Van Henry", "Wayfair", "Wallmart"];

export default function Summary() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [marketPlaces, setMarketPlaces] = useState(["All"]);

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [transactionSummery, setTransactionSummery] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        await GetTransactionsSummary().then((data) => {
          setIsLoading(true);
          setTransactionSummery(data);
        });
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  if (!transactionSummery)
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMarketPlaces(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          background: "#ffff",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            borderRadius: "18px 0px 0px 18px",
            boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
            p: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <FilterListIcon sx={{ color: "primary.Primary_Blue" }} />
              <Typography variant="h4" color="primary.Primary_Blue">
                Filter
              </Typography>
            </Stack>

            <Typography variant="body2" color="primary.Primary_Blue">
              Clear All
            </Typography>
          </Stack>
          <br></br>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography variant="body2">
              <b>Client</b>
            </Typography>
            <StyledTextBox
              fullWidth
              size="small"
              placeholder="Search by username"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="start"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>

          <Stack spacing={1} sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack spacing={1} sx={{ mb: 2 }}>
                <Typography variant="body2">
                  <b>Start Date</b>
                </Typography>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextBox {...params} size="small" />
                  )}
                  inputProps={{
                    placeholder: "Start Date",
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">
                  <b>End Date</b>
                </Typography>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextBox {...params} size="small" />
                  )}
                  inputProps={{
                    placeholder: "End Date",
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>

          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography variant="body2">
              <b>Factoring Type</b>
            </Typography>
            <StyledSelectTextBox select fullWidth defaultValue="All">
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Instant Factoring">Instant Factoring</MenuItem>
              <MenuItem value="Advance Factoring">Advance Factoring</MenuItem>
            </StyledSelectTextBox>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body2">
              <b>Marketplaces</b>
            </Typography>
            <StyledSelectTextBox
              select
              fullWidth
              multiple
              value={marketPlaces}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {marketPlacesNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={marketPlaces.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </StyledSelectTextBox>
          </Stack>
        </Grid>
        <Grid xs={8}>
          <Stack sx={{ p: 2, mb: 1 }}>
            <Typography variant="h4" color="primary.Primary_Blue">
              Vepay Summary
            </Typography>
          </Stack>

          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">
                    Total Transfers to Clients
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                   {formatCurrency(transactionSummery.totalTransfersToClients)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">
                    Seller’s Wallet Account
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                  {formatCurrency(transactionSummery.totalSellerWalletAccount)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">
                    Incentive Payables
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                  {formatCurrency(transactionSummery.totalIncentiveFeePayable)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">                 
                    Agency Fee Payables
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                  {formatCurrency(transactionSummery.totalAgencyFeePayable)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">
                    Vepay Revenue
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                  {formatCurrency(transactionSummery.totalVePayRevenue)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary
                  align="left"
                  variant="body2"
                ></TableCellSummary>
              </TableRow>
              <TableRow style={{ background: "#4471D3" }}>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Pure_White_Bg">
                    Total Balance
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.Pure_White_Bg">
                  {formatCurrency(transactionSummery.totalVePaySummaryBalance)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
            </TableBody>
          </Table>
          <br></br>
          <Stack sx={{ p: 2, mb: 1.5 }}>
            <Typography variant="h4" color="primary.Primary_Blue">
              Marketplace Summary
            </Typography>
          </Stack>

          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">
                    Payments Due
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                  {formatCurrency(transactionSummery.marketplaceSummary.totalPaymentsDue)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Gray_Dark">
                    Payments Recieved
                  </Typography>
                </TableCellSummary>

                <TableCellSummary align="right" variant="body2">
                  <Typography variant="h4" color="neutral.letter_Black">
                  {formatCurrency(transactionSummery.marketplaceSummary.totalPaymentsReceived)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
              <TableRow>
                <TableCellSummary
                  align="left"
                  variant="body2"
                ></TableCellSummary>
              </TableRow>
              <TableRow style={{ background: "#4471D3" }}>
                <TableCellSummary align="left" variant="body2">
                  <Typography variant="body1" color="neutral.Pure_White_Bg">
                    
                    Total Balance
                  </Typography>
                </TableCellSummary>

                <TableCellSummary
                  align="right"
                  variant="body2"
                  sx={{ borderRadius: "0px 0px 18px 0px" }}
                >
                  <Typography variant="h4" color="neutral.Pure_White_Bg">
                    
                  {formatCurrency(transactionSummery.marketplaceSummary.totalMarketplaceSummaryBalance)}
                  </Typography>
                </TableCellSummary>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
}
