import { BASE_URL } from "../utils/constants";

export const PostBusinessInformation = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/businesses`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        const data = await response.json();

        return {response, data};

    } catch (e) {
        throw e;
    }
}