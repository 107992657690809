import * as React from "react";
import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Stack,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TablePagination,
} from "@mui/material";
import { StyledTextBox } from "../../components/TextBox";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Moment from "moment";
import {
  StyledTableContainer,
  StyledTableCell,
} from "../../components/Table";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GetTransactions } from "../../services/transactions.services";
import { formatCurrency } from "../../utils/utilityFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingBox } from "../../components/Box";
import moment from "moment";

function TableData(props) {
  const [data, setData] = useState(props.data);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setData(props.data);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TableRow>
        <TableCell align="left" variant="body2">
          {moment(data.requestDate).format("MMM D, y")}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left" variant="body2">
          {data.countOfInvoices}
        </TableCell>
        <TableCell align="left" variant="body2">
          {formatCurrency(data.totalPurchases)}
        </TableCell>
        <TableCell align="left" variant="body2">
          {formatCurrency(data.totalVePayRevenue)}
        </TableCell>
        <TableCell align="left" variant="body2">
          {formatCurrency(data.totalAgencyFeePayable)}
        </TableCell>
        <TableCell align="left" variant="body2">
          {formatCurrency(data.incentiveFeePayable)}
        </TableCell>
        <TableCell align="left" variant="body2">
          {formatCurrency(data.marketplaceBalance)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                  <StyledTableCell align="left" variant="body2">
                    Invoice&nbsp;Date
                  </StyledTableCell>
                  <StyledTableCell align="center" variant="body2">
                    Order&nbsp;ID
                  </StyledTableCell>
                  <StyledTableCell align="center">Client Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Net Recievable Amount
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Net Amount Credited to Wallet
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    VePay Revenue
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Agency Fee Payable
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Incentive Fee Payable
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              {data.invoiceList.map((innerRow, index) => (
                <TableRow key={index}>
                  <TableCell align="left" variant="body2">
                    {moment(innerRow.invoiceDate).format("MMM D, y")}
                  </TableCell>
                  <TableCell align="center" variant="body2">
                    {innerRow.orderId}
                  </TableCell>
                  <TableCell align="center" variant="body2">
                    {innerRow.clientName}
                  </TableCell>
                  <TableCell align="right" variant="body2">
                    {formatCurrency(innerRow.netReceivableAmount)}
                  </TableCell>
                  <TableCell align="right" variant="body2">
                    {formatCurrency(innerRow.netAmountCreditedToWallet)}
                  </TableCell>
                  <TableCell align="center" variant="body2">
                    {formatCurrency(innerRow.vePayRevenue)}
                  </TableCell>
                  <TableCell align="right" variant="body2">
                    {formatCurrency(innerRow.agencyFeePayable)}
                  </TableCell>
                  <TableCell align="right" variant="body2">
                    {formatCurrency(innerRow.incentiveFeePayable)}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function DataTable() {
  const [startDate, setStartDate] = useState(
    Moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState(Moment().toDate());

  const [transactions, setTransactions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        await GetTransactions().then((data) => {
          setIsLoading(true);
          setTransactions(data);
        });
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  if (!transactions)
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          p: 5,
        }}
      >
        <Stack spacing={2} direction="row">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack spacing={2}>
              <Typography variant="body2">Start Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2">End Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack spacing={2}>
            <Typography variant="body2">Search Record</Typography>
            <StyledTextBox
              size="small"
              placeholder="Reference ID"
              endAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Stack>
        </Stack>

        <br></br>

        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Request Date</StyledTableCell>
                <StyledTableCell align="left">No. of Invoices</StyledTableCell>
                <StyledTableCell align="left">Total Purchase</StyledTableCell>
                <StyledTableCell align="left">
                  Total Vepay Revenue
                </StyledTableCell>
                <StyledTableCell align="left">
                  Total Agency Fee Payable
                </StyledTableCell>
                <StyledTableCell align="left">
                  Incentive Fee Payable
                </StyledTableCell>
                <StyledTableCell align="left">
                  Marketplace Balance
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <LoadingBox>
                  <CircularProgress />
                </LoadingBox>
              ) : (
                ""
              )}
              {transactions
                .sort(
                  (a, b) =>
                    parseFloat(b.requestDate) - parseFloat(a.requestDate)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <React.Fragment key={index}>
                    <TableData key={index} data={data} index={index} />
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={transactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
}
