import React, { useState } from "react";
import {
	Box,
	Chip,
	Grid
} from "@mui/material";
import Ledgers from "./Ledgers";
import LedgerBreakdown from "./LedgerBreakdown";

export default function MasterTable() {

	const [selectedLedger, setSelectecLedger] = useState(null);

	const ledgers = [
		{ name: 'Inventory' },
		{ name: 'Seller Wallet' },
		{ name: 'Incentive Fee' },
		{ name: 'Agency Fee' },
		{ name: 'Rev. Realized' },
		{ name: 'Rev. Recog.' },
		{ name: 'Amazon' },
		{ name: 'Amazon Receivable' },
		{ name: 'Revenue' },
		{ name: 'Client Withdrawal' },
		{ name: 'Capital' },
		{ name: 'Bank' }
	];

	return (
		<div>
			<Box
				sx={{
					background: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
					borderRadius: "18px",
					p: 5,
				}}
			>
				<Grid container spacing={1}>
					{ledgers.map((row, index) => (
						<Grid item xs="auto" key={index}>
							<Chip 
								label={row.name} 
								variant={selectedLedger === row.name ? '' : 'outlined'} 
								color={selectedLedger === row.name ? 'primary' : 'default'} 
								onClick={() => setSelectecLedger(row.name)} 
								onDelete={selectedLedger === row.name ? () => setSelectecLedger(null) : undefined}
							/>
						</Grid>
					))}
				</Grid>
				<br></br>
				{selectedLedger ? (<LedgerBreakdown />) : (<Ledgers />)}
			</Box>
			<br></br>
		</div>
	);
}
