import {
  Stack,
  Typography,
  Grid,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import React from "react";
import { ContainedSearchButton } from "../../../components/Buttons";
import { StyledTextBox } from "../../../components/TextBox";

export default function EditClientInformation() {
  return (
    <div>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Client Type
            </Typography>
          </Grid>
          <Grid xs={9}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Seller"
                  control={<Radio size="small" />}
                  label="Seller"
                  sx={{color:"neutral.letter_Black"}}
                />
                <FormControlLabel
                  value="Partner"
                  control={<Radio size="small" />}
                  label="Partner"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              User ID
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="00000001" />
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Username
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="mafazj" />
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              First Name
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="mafaz" />
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Last Name
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="Jaufer" />
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Email
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="mafaz@vepay.com" />
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Address
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="112,phoenix .arizona" />
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Country
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="United States" />
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Contact
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="+1 603 456 308" />
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="flex-start">
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Currency
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="USD ($)" />
          </Grid>
        </Grid>
        <Grid xs={1} />
        <Grid xs={5} container direction="row" alignItems="center">
          <Grid xs={3}>
            <Typography variant="body2" color="neutral.letter_Black">
              Timezone
            </Typography>
          </Grid>
          <Grid xs={9}>
            <StyledTextBox fullWidth value="UTC-4 New York, United States " />
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" spacing={1} alignItems="center">
        <Checkbox />
        <Typography variant="body2" color="neutral.letter_Black">
          Notify the changes for user.
        </Typography>
      </Stack>
      <br></br>
      <ContainedSearchButton variant="contained">
        Save changes
      </ContainedSearchButton>

      <br></br>
    </div>
  );
}
