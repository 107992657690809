import { styled } from "@mui/material/styles";
import { TextField, Select} from "@mui/material";

export const StyledTextBox = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    height: "2.5em",
  },
  '& .MuiOutlinedInput-notchedOutline': {
   
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
}));


export const StyledTextBoxNormal = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    height: "2.5em",
    // width:"25em"
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
}));

export const StyledSelectTextBox = styled(Select)(({ theme }) => ({
  borderRadius: "12px",
  borderColor: "white",
  height: "2.5em",
  // color: "#868B98",
}));

export const StyledTextArea = styled(TextField)(({ theme }) => ({
  boxShadow: ["none"],
  border: "1px solid #CED0D2",
  borderRadius: "8px",
}));

export const StyledTextBoxLogin = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    height: "2.5em",
    width: "28.75em",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormLabel-root": {
    color: "#FFFFFF",
  },
  "& .MuiInputBase-root": {
    color: "#black",
  },
  opacity: "0.75",
}));


export const StyledTextAreaModal = styled(TextField)(({ theme }) => ({
  boxShadow: ["none"],
  border: "1px solid #CED0D2",
  borderRadius: "8px",
  lineHeight:"150px",
}));

