import React from "react";
import {
  Stack,
  Typography,
  Grid,
  InputAdornment,
  ThemeProvider,
} from "@mui/material";
import { Theme } from "../../components/Theme";
import navLogo from "../../assets/navLogo.svg";
import users from "../../assets/users.svg";
import "./signinstyle.css";
import { ContainedButtonLogin } from "../../components/Buttons";
import Divider from "@mui/material/Divider";
import { StyledTextBoxLogin } from "../../components/TextBox";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";

export default function ForgetPassword() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="main">
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={5} container direction="row" justifyContent="center">
            <Stack alignItems="center">
              <img src={navLogo} alt="Vepay" />
              <Typography
                variant="h1"
                sx={{ fontWeight: "bold", color: "#FFFFFF", marginTop:"6%" }}
              >
                Admin Login
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #FFFFFF",
              height: "525px",
              marginTop: "5%",
            }}
          />
          <Grid xs={5} direction="row" justifyItems="center">
            <Stack alignItems="center" sx={{ marginLeft: "15%" }}>
              <img src={users} alt="Vepay" />
            </Stack>
            <Stack
              spacing={1}
              justifyContent="flex-start"
              sx={{ marginLeft: "25%",marginTop:"5%" }}
            >
              <Typography variant="h4" sx={{ color: "#FFFFFF" }}>
                Forget Password
              </Typography>
              <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
                Enter the email address associated with your account and we’ll
                send a link to reset your password.
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFFFFF", pt:"2%"}}>
                Email
              </Typography>
              <StyledTextBoxLogin
                placeholder="Placeholder"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person2OutlinedIcon sx={{ color: "#868B98" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <br></br>
            {/* <Stack spacing={1} alignItems="flex-start" sx={{marginLeft:"25%"}}>
                  <Typography variant="body1" sx={{color:"#FFFFFF"}}>Password</Typography>
                  <StyledTextBoxLogin  placeholder='Password' InputProps={{
                startAdornment: <InputAdornment position="start"><LockOutlinedIcon sx={{ color:"#868B98" }}/></InputAdornment>,
                endAdornment: <InputAdornment position="end"><VisibilityOutlinedIcon sx={{ color:"#868B98" }}/></InputAdornment>
              }}
              />
          <Stack direction="row" spacing={1} alignItems="flex-start">
          <FormControlLabel
            control={
              <Checkbox checked={password} onChange={handleChange} name="password" />
            }
            label="Remember me" variant="body2" defaultChecked color="success" 
          /> 
          <Typography variant="body2" sx={{paddingTop:"5%",color:"#FFFFFF"}}>Forget password</Typography>
          
         
          </Stack>
              
        </Stack> */}
            <br></br>

            <Stack
              spacing={1}
              alignItems="flex-start"
              sx={{ marginLeft: "25%" }}
            >
              <ContainedButtonLogin
                sx={{ width: "32.75em" }}
                fullWidth
                variant="contained"
              >
                Log In
              </ContainedButtonLogin>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
