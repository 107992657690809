import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CreateClientInformation from "./CreateClientInformation";
import BusinessInformation from "./BusinessInformation";
import BankInformation from "./BankInformation";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";

export default function AddInformation() {
  const [value, setValue] = useState("1");
  const [stepCheck, setStepCheck] = useState(1)


  useEffect(() => {
    if(localStorage.getItem('newClentStep')){
      setValue(localStorage.getItem('newClentStep'))
      setStepCheck(localStorage.getItem('newClentStep'))
    }
  }, [])
  
// console.log(stepCount)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <NavLink
            to="/client-admin"
            state={{ pageTitle: "Client Admina" }}
          >
            <IconButton>
              <ArrowBackIosIcon />
            </IconButton>
          </NavLink>
          <Typography variant="h3">Add New Client </Typography>
        </Stack>
      </Stack>
      <br></br>

      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
        }}
        pt={1}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "#CAD7F4" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Client Information"
                value="1"
                sx={stepCheck > 1 ? { textTransform: "capitalize", color: '#073ba5', borderBottom: 2, borderColor: "#073ba5" } : {textTransform: "capitalize"}}
              />
              <Tab
                label="Business Information"
                value="2"
                sx={stepCheck > 2 ? { textTransform: "capitalize", color: '#073ba5', borderBottom: 2, borderColor: "#073ba5" } : {textTransform: "capitalize"}}
              />
              <Tab
                label="Bank Information"
                value="3"
                sx={stepCheck > 3 ? { textTransform: "capitalize", color: '#073ba5', borderBottom: 2, borderColor: "#073ba5" } : {textTransform: "capitalize"}}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CreateClientInformation />
          </TabPanel>
          <TabPanel value="2">
            <BusinessInformation />
          </TabPanel>
          <TabPanel value="3">
            <BankInformation />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
