import React from 'react'
import { useState } from 'react';
import {Box, Tab, Typography} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Overview from './Overview';
import MasterTable from './MasterTable';

export default function Dashboard() {
    const [tabValue, setTabValue] = useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

  return (
    <div>   
        <Box sx={{ width: '100%', typography: 'body1' }}>
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label={<Typography variant='h3'>Overview</Typography>} value="1" sx={{textTransform: 'capitalize'}}/>
          <Tab label={<Typography variant='h3'>Master Table</Typography>} value="2" sx={{textTransform: 'capitalize'}}/>
        </TabList>
      </Box>
      <TabPanel value="1"><Overview /></TabPanel>
      <TabPanel value="2"><MasterTable /></TabPanel>
    </TabContext>
  </Box>
  </div>
  )
}
