import React from "react";
import { useState } from "react";

import {
  Stack,
  Typography,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid,
  InputAdornment,
  IconButton,
  Collapse,
  TableContainer,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StyledTextBox } from "../../components/TextBox";
import { StyledTableContainer, StyledTableCell } from "../../components/Table";
import SearchIcon from "@mui/icons-material/Search";
import Moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RedBox, GreenBox } from "../../components/Chip";
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import { TextButton, ContainedButton } from "../../components/Buttons";
import './style.css';

export default function TransferHistory() {
  const [startDate, setStartDate] = useState(
    Moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState(Moment().toDate());
  const [open, setOpen] = useState(false);

  const [openModel, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    // height: "80%",
    bgcolor: 'background.paper',
    border: '1px solid #FFFFFF',
    // boxShadow: 24,
    p: 4,
    borderRadius: "18px",
    boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
  };

  return (
    <div>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "0px 18px 18px 18px",
          p: 5,
        }}
      >
        <Stack spacing={2} direction="row">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack spacing={2}>
              <Typography variant="body2">Start Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2">End Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
        <br></br>

        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={4}>
            <Box
              sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 3 }}
              alignItems="center"
              height="7.5em"
            >
              <Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
                Total Transfer Request Amount
              </Typography>
              <Typography
                variant="h1"
                color="primary.Primary_Blue"
                textAlign="center"
              >
                $20,000.00
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 3 }}
              height="7.5em"
            >
              <Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
                Total Transfers to Clients
              </Typography>
              <Typography
                variant="h1"
                color="primary.Primary_Blue"
                textAlign="center"
              >
                $29,800.00
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row">
              <Box
                sx={{ border: "1px solid #D6D9E1", borderRadius: "8px 0px 0px 8px", p: 3 }}
                height="7.5em"
              >
                <Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
                  Approved Transfers
                </Typography>
                <Typography
                  variant="h1"
                  color="success.Success_Dk"
                  textAlign="center"
                >
                  34
                </Typography>
              </Box>
              <Box
                sx={{ border: "1px solid #D6D9E1", borderRadius: "0px 8px 8px 0px", p: 3 }}
                height="7.5em"
              >
                <Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
                  Rejected Transfers
                </Typography>
                <Typography
                  variant="h1"
                  color="danger.Danger_Dk"
                  textAlign="center"
                >
                  04
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          p: 5,
        }}
      >
        <Stack spacing={2} direction="row">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack spacing={2}>
              <Typography variant="body2">Start Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2">End Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack spacing={2}>
            <Typography variant="body2">Search Record</Typography>
            <StyledTextBox
              sx={{ width: "150%" }}
              fullWidth
              size="small"
              placeholder="Client ID / Clientname / Transfer ID"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="start"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">
                  Transfer Request Date
                </StyledTableCell>
                <StyledTableCell align="left">Total Transfer Requested</StyledTableCell>
                <StyledTableCell align="left">
                  Total Request Recieved
                </StyledTableCell>
                <StyledTableCell align="left">
                  Total Approved
                </StyledTableCell>
                <StyledTableCell align="left">
                  Total Pending
                </StyledTableCell>
                <StyledTableCell align="left">
                  Total Rejected
                </StyledTableCell>
                <StyledTableCell align="left">
                  Total Transferred
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  variant="body2"
                // sx={{ borderLeft: "4px solid #093BA7" }}
                >
                  31 Jan, 2022
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    variant="text"
                  >
                    {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </TableCell>
                <TableCell
                  align="left"
                  variant="body2"
                // sx={{ color: "#093BA7" }}
                >
                  $1,120.00
                </TableCell>
                <TableCell align="right" variant="body2">
                  2
                </TableCell>
                <TableCell align="right" variant="body2">
                  2
                </TableCell>
                <TableCell align="right" variant="body2">
                  0
                </TableCell>
                <TableCell align="right" variant="body2">
                  0
                </TableCell>
                <TableCell align="right" variant="body2">
                  $1,120.00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={10}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                            <StyledTableCell align="left">
                              Request Time
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Transfer Date
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Transfer Time
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Transfer ID
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Client ID
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Client Name
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Transfer Amount
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Review By
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Status
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" variant="body2">
                              08.30pm
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              31 Jan, 2022
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              04.30pm
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              1757833457
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              00012346
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              saajidM
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              $1,000.00
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              <Avatar sx={{ bgcolor: "#093BA7" }}></Avatar>
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              <GreenBox>Approved</GreenBox>
                            </TableCell>
                            <TableCell
                              align="left"
                              variant="body2"
                              sx={{ color: "#093BA7" }}
                            >
                              <b>View</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" variant="body2">
                              08.30pm
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              31 Jan, 2022
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              04.30pm
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              1757833457
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              00012346
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              saajidM
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              $1,000.00
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              <Avatar sx={{ bgcolor: "#093BA7" }}></Avatar>
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              <GreenBox>Approved</GreenBox>
                            </TableCell>
                            <TableCell
                              align="left"
                              variant="body2"
                              sx={{ color: "#093BA7" }}
                            >
                              <b>View</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" variant="body2">
                              08.30pm
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              31 Jan, 2022
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              04.30pm
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              1757833457
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              00012346
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              saajidM
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              $1,000.00
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              <Avatar sx={{ bgcolor: "#093BA7" }}></Avatar>
                            </TableCell>
                            <TableCell align="left" variant="body2">
                              <RedBox>Rejected</RedBox>
                            </TableCell>
                            <TableCell
                              align="left"
                              variant="body2"
                              sx={{ color: "#093BA7" }}
                            >
                              <TextButton onClick={handleOpen}>View</TextButton>

                              <Modal
                                open={openModel}
                                onClose={handleClose}
                              >
                                <Box sx={style}>
                                  <Typography variant="h3" fontWeight="700" align="center" sx={{ pb: 2 }}>Transfer Request</Typography>
                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Transaction&nbsp;ID
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2">1757833457</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Request&nbsp;Date
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2">31 Jan,2022</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Request&nbsp;Time
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          08.30pm
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Client&nbsp;ID
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          00012346
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Client&nbsp;Name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          saajidM
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Transfer&nbsp;Amount
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          $200.00
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Transfer&nbsp;Method
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          Payment&nbsp;Provider
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Client&nbsp;Bank
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          PingPong
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Client&nbsp;Account&nbsp;Number
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          ************7896
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Vepay&nbsp;Account&nbsp;Number
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          2345-3242-4242-2344
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Reviewed&nbsp;By
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                          <Avatar sx={{ bgcolor: "#093BA7", width: 28, height: 28 }} /> <Typography variant="body2" color="neutral.letter_Black">Mafaz J</Typography>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Status
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <Typography variant="body2" color="neutral.letter_Black">
                                          Transfer Approved
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                                    <Grid xs={8} container direction="row" alignItems="center">
                                      <Grid xs={5}>
                                        <Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
                                          Note&nbsp;to&nbsp;Client
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2} />
                                      <Grid xs={5}>
                                        <StyledTextBox sx={{ width: "17.4375em" }}></StyledTextBox>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container justifyContent="center" sx={{ pt: 3 }}>
                                    <ContainedButton alignItems="center" justifyContent="center">Done</ContainedButton>
                                  </Grid>
                                </Box>

                              </Modal>

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  variant="body2"
                // sx={{ borderLeft: "4px solid #FF9B43" }}
                >
                  30 Jan, 2022
                </TableCell>
                <TableCell
                  align="left"
                  variant="body2"
                // sx={{ color: "#093BA7" }}
                >
                  $1,225.00
                </TableCell>
                <TableCell align="right" variant="body2">
                  2
                </TableCell>
                <TableCell align="right" variant="body2">
                  3
                </TableCell>
                <TableCell align="right" variant="body2">
                  0
                </TableCell>
                <TableCell align="right" variant="body2">
                  0
                </TableCell>
                <TableCell align="right" variant="body2">
                  $1,225.00
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" variant="body2">
                  29 Jan, 2022
                </TableCell>
                <TableCell
                  align="left"
                  variant="body2"
                // sx={{ color: "#093BA7" }}
                >
                  $985.00
                </TableCell>
                <TableCell align="right" variant="body2">
                  2
                </TableCell>
                <TableCell align="right" variant="body2">
                  3
                </TableCell>
                <TableCell align="right" variant="body2">
                  0
                </TableCell>
                <TableCell align="right" variant="body2">
                  0
                </TableCell>
                <TableCell align="right" variant="body2">
                  $1,225.00
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

    </div>
  );
}
