import {
  Stack,
  Typography,
  Box,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TablePagination,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  ContainedSearchButton,
  TextButton,
} from "../../components/Buttons";
import { StyledSelectTextBox, StyledTextBox } from "../../components/TextBox";
import { StyledTableContainer, StyledTableCell } from "../../components/Table";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import { GetClientDetails } from "../../services/client.services";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingBox } from "../../components/Box";

export default function ClientInformation() {

  const [values, setValues] = useState({
    username:"",
    userId:"",
    email:"",
    businessName:"",
  });

  const [usernameError, setUsernameError] = useState({ error: false, message: "" });
  const [userIdError, setUserIdError] = useState({
    error: false,
    message: "",
  });
  const [emailError, setEmailError] = useState({
    error: false,
    message: "",
  });
  const [businessNameError, setBusinessNameError] = useState({
    error: false,
    message: "",
  });


  const handleUpdateData = (event) => {
    event.preventDefault();
    let error = false;


    if (values.username === "") {
      setUsernameError({ error: true, message: "Please enter a username" });
      error = true;
    } else {
      setUsernameError({ error: false, message: "" });
      error = false;
    }

    if (values.userId === "") {
      setUserIdError({ error: true, message: "Please enter a userID" });
      error = true;
    } else {
      setUserIdError({ error: false, message: "" });
      error = false;
    }

    if (values.email === "") {
      setEmailError({ error: true, message: "Please give a Email" });
      error = true;
    } else {
      setEmailError({ error: false, message: "" });
      error = false;
    }

    if (values.businessName === "") {
      setBusinessNameError({ error: true, message: "Enter a business name" });
      error = true;
    } else {
      setBusinessNameError({ error: false, message: "" });
      error = false;
    }

    if (error) {
      //alert("Please fill in all the search fields");
      return;
    }
    
  };

  const [clientData, setClientData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

     useEffect(() => {
       (async () => {
       setIsLoading(false);
         try {
           await GetClientDetails().then((data) => {
               setIsLoading(true);
               setClientData(data);
           });
           setIsLoading(false);
         } catch (error) {
           throw error;
         }
       })();
     }, []);
   
     if (!clientData) return (
       <LoadingBox>
         <CircularProgress />
       </LoadingBox>
     );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
        }}
      >
        <Stack direction="row" justifyContent="center" spacing={2} p={5}>
          <StyledTextBox
             
            fullWidth
            label="Username"
            size="small"
            error={usernameError.error}
            value={values.username}
            onChange={(e) =>
              setValues({ ...values, username: e.target.value })
            }
           
          >
             {usernameError.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setUsernameError({ error: false, message: "" })
                    }
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {usernameError.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
          </StyledTextBox>
          <StyledTextBox 
          
          fullWidth label="User ID" size="small"
          error={userIdError.error}
          value={values.userId}
          onChange={(e) =>
            setValues({ ...values, userId: e.target.value })
          }
          >
        {userIdError.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setUserIdError({ error: false, message: "" })
                    }
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {userIdError.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
          </StyledTextBox>
          <StyledTextBox 
         
          fullWidth label="Email" size="small"
          error={emailError.error}
          value={values.email}
          onChange={(e) =>
            setValues({ ...values, email: e.target.value })
          }
          >
             {emailError.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setEmailError({ error: false, message: "" })
                    }
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {emailError.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
          </StyledTextBox>
         
          <StyledTextBox
            fullWidth
            label="Business Name"
            size="small"
            error={businessNameError.error}
            value={values.businessName}
            onChange={(e) =>
              setValues({ ...values, businessName: e.target.value })
            }
          >
            {businessNameError.error ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  pt={0.5}
                >
                  <CancelOutlined
                    sx={{
                      fontSize: "0.875em",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setBusinessNameError({ error: false, message: "" })
                    }
                  />
                  <Typography variant="body3" color="red" textAlign="left">
                    {businessNameError.message}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            

          </StyledTextBox>
        </Stack>

        <Stack alignItems="center">
          <ContainedSearchButton
            variant="contained"
            startIcon={<PersonSearchOutlinedIcon />}
            sx={{ width: "40%" }}
            onClick={(event) => handleUpdateData(event)}
          >
            Search Client
          </ContainedSearchButton>
        </Stack>
        <br></br>
        <Stack alignItems="center">
          <Typography variant="body2">
            Please use one or more search fields to search client information.
          </Typography>
        </Stack>
        <br></br>
      </Box>
      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
        }}
        pl={5}
        pr={5}
        pb={5}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          pt={1}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={clientData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Typography variant="body2" color="neutral.Gray_Dark">
              Sort by:
            </Typography>
            <StyledSelectTextBox value="Username" select>
              <MenuItem value="Username">Username</MenuItem>
            </StyledSelectTextBox>
          </Stack>
        </Stack>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Username</StyledTableCell>
                <StyledTableCell align="left">User ID</StyledTableCell>
                <StyledTableCell align="left">Business ID</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Contact</StyledTableCell>
                <StyledTableCell align="left">Since Boarding</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow>
                    <TableCell align="left" variant="body2">
                      {row.username}
                    </TableCell>
                    <TableCell align="left" variant="body2">
                      234524
                    </TableCell>
                    <TableCell align="left" variant="body2">
                      234524
                    </TableCell>
                    <TableCell align="left" variant="body2">
                      {row.email}
                    </TableCell>
                    <TableCell align="left" variant="body2">
                      {row.contactNo}
                    </TableCell>
                    <TableCell align="left" variant="body2">
                      1y 2 mo
                    </TableCell>
                    <TableCell align="center">
                      <NavLink
                        to="/client-admin/client-information/edit-information"
                        style={{ textDecoration: "none" }}
                      >
                        <TextButton variant="text">Edit</TextButton>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </div>
  );
}
