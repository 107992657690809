import React from "react";
import { useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { StyledTextBox } from "../../components/TextBox";
import { ContainedButton } from "../../components/Buttons";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PasswordStrengthBar from "react-password-strength-bar";
import { UpdatePasswordData } from "../../services/settings.services";
import CancelOutlined from "@mui/icons-material/CancelOutlined";

export default function PasswordSettings() {
  const [passwordValues, setPasswordValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [errors, setErrors] = useState({
    currentPassword: { error: false, message: "" },
    newPassword: { error: false, message: "" },
    confirmNewPassword: { error: false, message: "" },
  });

  // password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleUpdatePasswordData = (event) => {
    event.preventDefault();
    let error = false;
    const requiredfields = Object.keys(errors)
    for (let i = 0; i < requiredfields.length; i++){
      const field = requiredfields[i]
      if (passwordValues[field] === ""){
        let errorMessages 
        switch (field) {
          case "currentPassword":
            errorMessages = "Enter the current password"
            break;
          case "newPassword":
            errorMessages ="Enter the new password"
            break;
          case "confirmNewPassword":
            errorMessages ="Passwords don't match"
            break;
          default:
            errorMessages ="Please fill all the required fields"
        }
       
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { error:true, message:errorMessages}
        }))
        error=true
      }else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: {
            error: false,
            message: "",
          },
        }));
        error = false;
      }
    }
    

    

    // new password length
    if (passwordValues.newPassword.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: {
          error: true,
          message: "Passwords length need to be more than 8 Characters",
        },
      }));
      error = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: {
          error: false,
          message: "",
        },
      }));
      error = false;
    }

    if (passwordValues.newPassword !== passwordValues.confirmNewPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword:{
          error: true,
          message: "Passwords don't match",
        },
      }));
      error = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword: {
          error: false,
          message: "",
        },
      }));
      error = false;
    }

    console.log(errors)

    if (!error) {
      const passwordData = {
        username: sessionStorage.getItem("userName"),
        password: passwordValues.newPassword,
      };
      UpdatePasswordData(passwordData).then((response) => {
        console.log(response);
        if (response.ok) {
          setUpdated(true);
        //   console.log("text" + updated)
        }
        else{
            setUpdated(false);
          }
      });
    }
  };

  return (
    <div>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          p: 5,
        }}
      >
        <Typography variant="h4" sx={{ color: "primary.Primary_Blue" }}>
          Password Settings
        </Typography>
        <br></br>
        <Typography variant="body2" sx={{ color: "neutral.letter_Black" }}>
          Please enter your current password to change your password
        </Typography>
        <br></br>

        <Grid
          xs={5}
          md={5}
          lg={5}
          container
          direction="row"
          alignItems="center"
        >
          <Grid xs={3}>
            <Typography
              variant="body2"
              color="neutral.letter_Black"
              sx={{ fontWeight: "bold" }}
            >
              Current Password
            </Typography>
          </Grid>
          <Grid xs={9} padding="2%">
            <StyledTextBox
              fullWidth
              error={errors.currentPassword.error}
              placeholder="Enter the current password"
              id="outlined-adornment-weight"
              value={passwordValues.currentPassword}
              onChange={(e) =>
                setPasswordValues({
                  ...passwordValues,
                  currentPassword: e.target.value,
                })
              }
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
              {errors.currentPassword.error ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                pt={0.5}
              >
                <CancelOutlined
                  sx={{ fontSize: "0.875em", color: "red", cursor: "pointer" }}
                  onClick={() =>
                    setErrors({
                      ...errors,
                      currentPassword: { error: false, message: "" },
                    })
                  }
                />
                <Typography variant="body3" color="red" textAlign="left">
                  {errors.currentPassword.message}
                </Typography>
              </Stack>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid
          xs={5}
          md={5}
          lg={5}
          container
          direction="row"
          alignItems="center"
        >
          <Grid xs={3}>
            <Typography
              variant="body2"
              color="neutral.letter_Black"
              sx={{ fontWeight: "bold" }}
            >
              New Password
            </Typography>
          </Grid>
          <Grid xs={9} padding="2%">
            <StyledTextBox
              fullWidth
              placeholder="Enter the new password"
              error={errors.newPassword.error}
              value={passwordValues.newPassword}
              onChange={(e) =>
                setPasswordValues({
                  ...passwordValues,
                  newPassword: e.target.value,
                })
              }
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.newPassword.error ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                pt={0.5}
              >
                <CancelOutlined
                  sx={{ fontSize: "0.875em", color: "red", cursor: "pointer" }}
                  onClick={() =>
                    setErrors({
                      ...errors,
                      newPassword: { error: false, message: "" },
                    })
                  }
                />
                <Typography variant="body3" color="red" textAlign="left">
                  {errors.newPassword.message}
                </Typography>
              </Stack>
            ) : (
              ""
            )}

            {passwordValues.newPassword.length <= 0 ? (
              ""
            ) : (
              <PasswordStrengthBar
                password={passwordValues.newPassword}
                shortScoreWord=""
                scoreWords=""
                minLength="8"
              />
            )}
          </Grid>
        </Grid>

        <Grid
          xs={5}
          md={5}
          lg={5}
          container
          direction="row"
          alignItems="center"
        >
          <Grid xs={3}>
            <Typography
              variant="body2"
              color="neutral.letter_Black"
              sx={{ fontWeight: "bold" }}
            >
              Password
            </Typography>
          </Grid>
          <Grid xs={9} padding="2%">
            <StyledTextBox
              fullWidth
              placeholder="Enter the Password"
              error={errors.confirmNewPassword.error}
              id="outlined-adornment-weight"
              value={passwordValues.confirmNewPassword}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) =>
                setPasswordValues({
                  ...passwordValues,
                  confirmNewPassword: e.target.value,
                })
              }
            />
            {errors.confirmNewPassword.error ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                pt={0.5}
              >
                <CancelOutlined
                  sx={{ fontSize: "0.875em", color: "red", cursor: "pointer" }}
                  onClick={() =>
                    setErrors({
                      ...errors,
                      confirmNewPassword: { error: false, message: "" },
                    })
                  }
                />
                <Typography variant="body3" color="red" textAlign="left">
                  {errors.confirmNewPassword.message}
                </Typography>
              </Stack>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-start">
          <Grid
            xs={5}
            container
            direction="row"
            alignItems="center"
            sx={{ pt: 4 }}
          >
            <Grid xs={3}>
              <ContainedButton
                fullWidth
                sx={{ height: "3em" }}
                onClick={(event) => handleUpdatePasswordData(event)}
              >
                Update&nbsp;Now
              </ContainedButton>
            </Grid>
            <Grid xs={9}>
              {updated ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ fontSize: 20, color: "#00AD47", fontWeight: "600" }}
                  />
                  <Typography variant="body2" color="#00AD47" fontWeight="600">
                    Changes have been successfully saved!
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
