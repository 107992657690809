import React from "react";
import {
	TableCell,
	Table,
	TableHead,
	TableRow,
	TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableContainer } from "../../components/Table";

export default function LedgerBreakdown() {


	return (
		<StyledTableContainer>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
						<StyledTableCell align="left">Date</StyledTableCell>
						<StyledTableCell align="left">Order ID</StyledTableCell>
						<StyledTableCell align="left">Type</StyledTableCell>
						<StyledTableCell align="left">Description</StyledTableCell>
						<StyledTableCell align="left">Debit</StyledTableCell>
						<StyledTableCell align="left">Credit</StyledTableCell>
						<StyledTableCell align="left">Accumulation</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell align="left" variant="body2">

						</TableCell>
						<TableCell align="left" variant="body2">

						</TableCell>
						<TableCell align="left" variant="body2">

						</TableCell>
						<TableCell align="left" variant="body2">
						</TableCell>
						<TableCell align="left" variant="body2">
						</TableCell>
						<TableCell align="left" variant="body2">
						</TableCell>
						<TableCell align="center">

						</TableCell>
					</TableRow>

				</TableBody>
			</Table>
		</StyledTableContainer>
	);
}
