import React, { useState } from "react";
import {
	TableCell,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TablePagination,
	Typography
} from "@mui/material";
import { StyledTableCell, StyledTableContainer } from "../../components/Table";
import { extractAmazonId, formatCurrency } from "../../utils/utilityFunctions";
import moment from "moment";

export default function LedgerBreakdown(props) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	return (
		<>
			<StyledTableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
							<StyledTableCell align="left">Date</StyledTableCell>
							<StyledTableCell align="left">Marketplace</StyledTableCell>
							<StyledTableCell align="left">Region</StyledTableCell>
							<StyledTableCell align="left">Store Name</StyledTableCell>
							<StyledTableCell align="left">Order ID</StyledTableCell>
							<StyledTableCell align="left">Description</StyledTableCell>
							<StyledTableCell align="left">Debit</StyledTableCell>
							<StyledTableCell align="left">Credit</StyledTableCell>
							<StyledTableCell align="left">Balance</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.ledgerDataBreakdown.length === 0 ? (
							<TableRow>
								<TableCell
									colSpan={8}
									align="center"
									sx={{ padding: "10%" }}
								>
									<Typography variant="h5">
										<b>No results found</b>
									</Typography>
								</TableCell>
							</TableRow>
						) : (
							props.ledgerDataBreakdown
								.sort((a, b) =>  new Date(`${b.createdDate}T${b.createdTime}`) - new Date(`${a.createdDate}T${a.createdTime}`))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
									<TableRow key={index}>
										<TableCell align="left" variant="body2">
											{moment(row.createdDate).format("MMM D, y")}
										</TableCell>
										<TableCell align="left" variant="body2">
											Amazon
										</TableCell>
										<TableCell align="left" variant="body2">
											
										</TableCell>
										<TableCell align="left" variant="body2">
										</TableCell>
										<TableCell align="left" variant="body2">
											{extractAmazonId(row.meta)}
										</TableCell>
										<TableCell align="left" variant="body2">
											{row.description}
										</TableCell>
										<TableCell align="left" variant="body2">
											{formatCurrency(row.debit)}
										</TableCell>
										<TableCell align="left" variant="body2">
											{formatCurrency(row.credit)}
										</TableCell>
										<TableCell align="center">
										</TableCell>
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={props.ledgerDataBreakdown.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</>
	);
}
