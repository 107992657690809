import React, { useEffect } from "react";
import { useState } from "react";

import {
  Stack,
  Typography,
  Box,
  // MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid,
  InputAdornment,
  IconButton,
  // TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  StyledTextBox,
  StyledSelectTextBox,
  StyledTextArea,
} from "../../components/TextBox";
import { StyledTableContainer, StyledTableCell } from "../../components/Table";
import SearchIcon from "@mui/icons-material/Search";
import Moment from "moment";
import { BlueBox, RedBox, YellowBox, GreenBox } from "../../components/Chip";
import Avatar from "@mui/material/Avatar";
import {
  ContainedButton,
  TextButton,
  TextButtonModal,
} from "../../components/Buttons";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import {
  GetSupportTicketData,
  GetRequestsCountData,
} from "../../services/support.services";

export default function Support() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    height: "700px",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    // borderTop:"6px solid red",
    // boxShadow: 24,
    p: 4,
    boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
    borderRadius: "18px",
  };

  const [supportData, setSupportData] = useState(null);

  const [requestData, setRequestData] = useState(null);

  const [modalValues, setModalValues] = useState({
    referenceNo: "",
    ticketSubject: "",
    issueType: "",
    ticketDescription: "",
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = (event) => {
    setModalValues({
      ticketreferenceNo: event.currentTarget.referenceNo,
      ticketSubject: event.currentTarget.ticketSubject,
      issueType: event.currentTarget.issueType,
      ticketDescription: event.currentTarget.ticketDescription,
    });
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const [startDate, setStartDate] = useState(
    Moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState(Moment().toDate());

  useEffect(() => {
    (async () => {
      try {
        await GetRequestsCountData().then((data) => {
          setRequestData(data);
          // console.log(data.AvgResponseTime);
        });
        await GetSupportTicketData().then((data) => {
          setSupportData(data);
          // console.log(data);
        });
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  if (!supportData || !requestData) return null;

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Support Requests</Typography>
      </Stack>
      <br></br>

      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          p: 5,
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={4}>
            <Box
              sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 2 }}
              alignItems="center"
            >
              <Typography variant="body1" textAlign="center">
                Total Pending Requests
              </Typography>

              <Typography
                variant="h1"
                color="primary.Primary_Blue"
                textAlign="center"
              >
                {requestData.TicketPendingCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 2 }}
            >
              <Typography variant="body1" textAlign="center">
                Total In-progress Requests
              </Typography>
              <Typography
                variant="h1"
                color="primary.Primary_Blue"
                textAlign="center"
              >
                {requestData.TicketInProgressCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 2 }}
            >
              <Typography variant="body1" textAlign="center">
                Average Response Time
              </Typography>
              <Typography
                variant="h1"
                color="warning.Warning_Dk"
                textAlign="center"
              >
                {requestData.AvgResponseTime} Hrs
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <br></br>

        <Stack spacing={2} direction="row">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack spacing={2}>
              <Typography variant="body2">Start Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2">End Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <StyledTextBox {...params} size="small" />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack spacing={2}>
            <Typography variant="body2">Search Record</Typography>
            <StyledTextBox
              fullWidth
              size="small"
              placeholder="Reference ID"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="start"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant="body2">Show Requests</Typography>
            <StyledTextBox
              fullWidth
              size="small"
              placeholder="All Requests"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="start"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>
        </Stack>

        <br></br>

        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Request Date</StyledTableCell>
                <StyledTableCell align="left">Time</StyledTableCell>
                <StyledTableCell align="left">Client ID</StyledTableCell>
                <StyledTableCell align="left">Client Name</StyledTableCell>
                <StyledTableCell align="left">Reference ID</StyledTableCell>
                <StyledTableCell align="left">
                  Request Catergory
                </StyledTableCell>
                <StyledTableCell align="left">Subject</StyledTableCell>
                <StyledTableCell align="left">Review By</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                {/* <StyledTableCell align="left">
                  Total Pending Request
                </StyledTableCell> */}
                {/* <StyledTableCell align="left">
                  Total In-progress Request
                </StyledTableCell> */}
                {/* <StyledTableCell align="left">Total Request</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {supportData.map((row) => (
                <TableRow>
                  <TableCell
                    align="left"
                    variant="body2"
                    sx={{ borderLeft: "4px solid #093BA7" }}
                  >
                    31 Jan, 2022
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    11.30pm
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    00012346
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    saajidM
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    {row.referenceNo}
                  </TableCell>
                  <TableCell
                    align="left"
                    variant="body2"
                    sx={{ color: "#093BA7" }}
                  >
                    {row.issueType}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    {row.ticketSubject}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    <Avatar
                      src="/broken-image.jpg"
                      sx={{ bgcolor: "#093BA7" }}
                    />
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    {(() => {
                      if (row.status === "RESOLVED") {
                        return (
                          <GreenBox>
                            <b>RESOLVED</b>
                          </GreenBox>
                        );
                      } else if (row.status === "IN_PROGRESS") {
                        return (
                          <YellowBox>
                            <b>IN PROGRESS</b>
                          </YellowBox>
                        );
                      } else if (row.status === "CANCELLED") {
                        return (
                          <RedBox>
                            <b>CANCELLED</b>
                          </RedBox>
                        );
                      } else if (row.status === "PENDING") {
                        return (
                          <BlueBox>
                            <b>PENDING</b>
                          </BlueBox>
                        );
                      }
                    })()}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    <TextButton
                      onClick={handleOpen}
                      referenceNo={row.referenceNo}
                      ticketSubject={row.ticketSubject}
                      issueType={row.issueType}
                      ticketDescription={row.ticketDescription}
                    >
                      View
                    </TextButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {/* place the modal here */}
        {/* Modal Section of the view  */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginBottom: "1%" }}
            >
              Support Request Ref: {modalValues.referenceNo}
            </Typography>
            <Grid container alignItems="center" sx={{ marginBottom: "2%", marginTop:"4%" }}>
              <Grid md={5}>
                <Stack direction="row" spacing={8}>
                  <Typography>Request Date</Typography>
                  <Typography sx={{ fontWeight: "400" }}>
                    30th Jan,2022
                  </Typography>
                </Stack>
              </Grid>
              <Grid md={2}></Grid>
              <Grid md={5}>
                <Stack direction="row" spacing={8} justifyContent="center" alignItems="center">
                  <Typography>Status</Typography>
                  <StyledSelectTextBox
                    value="United states"
                    sx={{ width: "200px" }}
                  >
                    <MenuItem
                      value="DASHBOARD_ISSUE"
                      sx={{ fontWeight: "400" }}
                    >
                      Pending
                    </MenuItem>
                    <MenuItem value="WIDGET_ISSUE" sx={{ fontWeight: "400" }}>
                      In progress
                    </MenuItem>
                    <MenuItem
                      value="DASHBOARD_ISSUE"
                      sx={{ fontWeight: "400" }}
                    >
                      Resolved
                    </MenuItem>
                    <MenuItem value="WIDGET_ISSUE" sx={{ fontWeight: "400" }}>
                      Cancelled
                    </MenuItem>
                  </StyledSelectTextBox>
                </Stack>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid md={5}>
                <Stack direction="row" spacing={8}>
                  <Typography>Request Time</Typography>
                  <Typography sx={{ fontWeight: "400" }}>08.24pm</Typography>
                </Stack> 
              </Grid>
              <Grid md={2}></Grid>
              <Grid md={5}>
                <Stack direction="row" spacing={8} alignItems="center">
                  <Typography>Reviewed By</Typography>
                  <Avatar src="/broken-image.jpg" sx={{ bgcolor: "#093BA7" }} />
                </Stack>
              </Grid>
            </Grid>
            <Typography
              sx={{ mt: 2, mb: 2, fontWeight: "bold" }}
              variant="body1"
            >
              Request Subject
            </Typography>
            <StyledTextBox
              fullWidth
              // placeholder="lorem ipsum dolar sit a cominuhocs"
              disabled
              value={modalValues.ticketSubject}
            ></StyledTextBox>
            <Typography sx={{ mt: 2, mb: 2 }}>Issue Catergory</Typography>
            <StyledTextBox
              disabled
              fullWidth
              value={modalValues.issueType}
            ></StyledTextBox>
            <Typography sx={{ mt: 2, mb: 2 }}>Issue Description</Typography>
            <StyledTextArea
              value={modalValues.ticketDescription}
              multiline
              aria-label="minimum height"
              maxRows={6}
              disabled
              style={{ width: "100%" }}
            />
            <Stack
              direction="row"
              spacing={3}
              sx={{ mt: 4 }}
              justifyContent="center"
            >
              <ContainedButton sx={{ width: "107px" }}>Done</ContainedButton>
              <TextButtonModal sx={{ width: "107px" }}>Cancel</TextButtonModal>
            </Stack>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
