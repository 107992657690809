import React from "react";
import { useState } from "react";
import { Box,Typography, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PendingRequests from "./PendingRequests";
import TransferHistory from "./TransferHistory";
import { StyledTabs, StyledTab } from "../../components/Tabs";

export default function Transfers() {
  
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Transfers</Typography>
      </Stack>
      <br></br>
      <Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderColor: "divider" }}>
            <StyledTabs onChange={handleChange} value={tabValue}>
              <StyledTab
                label={<Typography variant="body1">Pending Requests</Typography>}
                value="1"
                sx={{ textTransform: "capitalize" }}
              />
              <StyledTab
                label={<Typography variant="body1">Transfer History</Typography>}
                value="2"
                sx={{ textTransform: "capitalize" }}
              />
            </StyledTabs>
          </Box>
          <TabPanel value="1" sx={{padding: 0}}>
            <PendingRequests />
          </TabPanel>
          <TabPanel value="2" sx={{padding: 0}}>
            <TransferHistory />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
