// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/bckgrnd.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.main{\n    min-height: 100vh;\n    display: flex;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: auto;\n    background-size: 100%;\n}\n\n\n\n/* hr{ \n  \n    border:         none;\n    margin-top:     10vh;\n    margin-left: 8%;\n    border-left:    3px solid hsla(200, 10%, 50%,100);\n    height:         80vh;\n    width:          1px;       \n} */", "",{"version":3,"sources":["webpack://./src/view/SignIn/signinstyle.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,aAAa;IACb,yDAAiD;IACjD,4BAA4B;IAC5B,qBAAqB;IACrB,qBAAqB;AACzB;;;;AAIA;;;;;;;;GAQG","sourcesContent":["\n.main{\n    min-height: 100vh;\n    display: flex;\n    background-image: url(\"../../assets/bckgrnd.jpg\");\n    background-repeat: no-repeat;\n    background-size: auto;\n    background-size: 100%;\n}\n\n\n\n/* hr{ \n  \n    border:         none;\n    margin-top:     10vh;\n    margin-left: 8%;\n    border-left:    3px solid hsla(200, 10%, 50%,100);\n    height:         80vh;\n    width:          1px;       \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
