import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const GreenBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ECFDF3",
  color: "#00AD47",
  borderRadius: "4px",
  textAlign: "center",
  width: "auto",
  textTransform: 'uppercase',
  fontSize: '0.875em', //14px
  fontWeight: 700,
  padding: '3%',
}));

export const RedBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#FEEFEE",
  color: "#EE5D50",
  borderRadius: "4px",
  textAlign: "center",
  width: "auto",
  textTransform: 'uppercase',
  fontSize: '0.875em', //14px
  fontWeight: 700,
  padding: '3%',
}));

export const BlueBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#CAD7F4",
  color: "#4471D3",
  borderRadius: "4px",
  textAlign: "center",
  width: "auto",
  textTransform: 'uppercase',
  fontSize: '0.875em', //14px
  fontWeight: 700,
  padding: '3%',
}));

export const YellowBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#FCF0E3",
  color: "#FF9B43",
  borderRadius: "4px",
  textAlign: "center",
  width: "auto",
  textTransform: 'uppercase',
  fontSize: '0.875em', //14px
  fontWeight: '700',
  padding: '3%',
}));
