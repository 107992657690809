import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter,
  Route,
  Routes,
  // Navigate
} from "react-router-dom";
import Navigation from "./layouts/Navigation";
import ClientAdmin from "./view/ClientAdmin/";
import { Theme } from "./components/Theme";
import EditInformation from "./view/ClientAdmin/Information/";
import AddInformation from "./view/ClientAdmin/NewInformation";
import TransactionsNav from "./view/Transactions";
import Support from "./view/Support";
import Dashboard from "./view/Dashboard";
import Summary from "./view/Dashboard/Summary";
import Transfers from "./view/Transfers";

import Settings from "./view/Settings";
import SignIn from "./view/SignIn";
import ForgetPassword from "./view/SignIn/ForgetPassword";
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigation />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/summary" element={<Summary />} />
            <Route path="/client-admin" element={<ClientAdmin />} />
            <Route path="/client-admin/client-information/edit-information" element={<EditInformation />} />
            <Route path="/client-admin/client-information/new-client" element={<AddInformation />} />
            <Route path="/transactions" element={<TransactionsNav />} />
            <Route path="/transfers" element={<Transfers />} />
            <Route path="/support" element={<Support />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
