import React from "react";
// import { useState, useEffect } from "react";

import {
	Stack,
	Typography,
	// Box,
	// Menu,
	// TableBody,
	// TableCell,
	// TableHead,
	// TableRow,
	// Table,
	Grid,
	// MenuItem,
} from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { StyledTextBox } from "../../components/TextBox";
// import { StyledTableContainer, StyledTableCell } from "../../components/Table";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import Moment from "moment";
import Chart from "./Charts";
// import CircularProgress from "@mui/material/CircularProgress";
import {
	ContainerBox,
	// LoadingBox
} from "../../components/Box";
// import {
// 	OutlinedButton,
// 	RoundEdgeContainedButton,
// } from "../../components/Buttons";
// import {
// 	GetDashboardAccountBalances,
// 	GetDashboardAccounts,
// } from "../../services/dashboard.services";
// import { formatCurrency } from "../../utils/utilityFunctions";
// import moment from "moment";
import usaflag from "../../assets/united-states.png";
import ausFlag from "../../assets/aus.png";
import canadaFlag from "../../assets/canada.png";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

export default function Overview() {
	// const [startDate, setStartDate] = useState(
	// 	Moment().subtract(7, "days").toDate()
	// );
	// const [endDate, setEndDate] = useState(Moment().toDate());
	// const [anchorEl, setAnchorEl] = useState(null);
	// const [isLoading, setIsLoading] = useState(false);
	// const [accountBalance, setAccountBalance] = useState(false);
	// const [accounts, setAccounts] = useState(false);

	// useEffect(() => {
	// 	(async () => {
	// 		setIsLoading(false);
	// 		try {
	// 			await GetDashboardAccountBalances().then((data) => {
	// 				setIsLoading(true);
	// 				setAccountBalance(data);
	// 			});
	// 			setIsLoading(false);
	// 		} catch (error) {
	// 			throw error;
	// 		}
	// 	})();
	// }, [accountType]);

	// if (!accountBalance || !accounts || isLoading)
	// 	return (
	// 		<LoadingBox>
	// 			<CircularProgress />
	// 		</LoadingBox>
	// 	);

	// const open = Boolean(anchorEl);
	// const handleClick = (event) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	return (
		<div>
			<Grid container spacing={2} justifyContent="space-between">
				<Grid item md={12}>
					<ContainerBox>
						<Typography variant="h4" color="primary.Primary_Blue">
							Key Statistics
						</Typography>
					</ContainerBox>
				</Grid>
			</Grid>
			<br></br>
			<Grid container spacing={2}>
				<Grid item md={6} spacing={2}>
					<ContainerBox>
						<Stack>
							<Typography variant="h4" color="primary.Primary_Blue">
								Primary Balances
							</Typography>
							<br></br>
						</Stack>
						<Grid container alignItems="center">
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>

								</Grid>
								<Grid item md={3}>
									<Typography variant="body1" color="green">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body1">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body1">
										USD 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>
									<img src={usaflag} style={{ width: '80%' }} alt="usa" />
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										USD 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>
									<img src={canadaFlag} style={{ width: '80%' }} alt="canada" />
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										CAD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										CAD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										CAD 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>
									<img src={ausFlag} style={{ width: '80%' }} alt="austailia" />
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										AUS 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										AUS 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										AUS 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>

								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										Available&nbsp;Balance
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										Receivable&nbsp;Balance
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										Marketplace&nbsp;Balance
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
								<Grid item md={1}>

								</Grid>
								<Grid item md={3}>
									<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
										<FactCheckIcon color="primary" />
										<Typography variant="body1">
											2755
										</Typography>
									</Stack>
								</Grid>
								<Grid item md={3}>
									<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
										<TrendingUpIcon color="primary" />
										<Typography variant="body1">
											$0.00
										</Typography>
									</Stack>
								</Grid>
								<Grid item md={3}>
									<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
										<TrendingDownIcon color="primary" />
										<Typography variant="body1">
											2.8%
										</Typography>
									</Stack>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
								<Grid item md={1}>

								</Grid>
								<Grid item md={3} textAlign="center">
									<Typography variant="body3">
										Total Number of Invoices Factored
									</Typography>
								</Grid>
								<Grid item md={3} textAlign="center">
									<Typography variant="body3">
										Avg. Invoice Value
									</Typography>
								</Grid>
								<Grid item md={3} textAlign="center">
									<Typography variant="body3">
										Avg. Return %
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</ContainerBox>
				</Grid>
				<Grid item md={6}>
					<Chart
						title="Total Transfers Amount"
						color="#093BA7"
						chartType="column"
					/>
				</Grid>
			</Grid>
			<br></br>
			<Grid container spacing={2}>
				<Grid item md={6} spacing={2}>
					<ContainerBox >
						<Stack>
							<Typography variant="h4" color="primary.Primary_Blue">
								VePay Wallet Stats
							</Typography>
							<br></br>
						</Stack>
						<Grid container>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>

								</Grid>
								<Grid item md={3}>
									<Typography variant="body1" color="green">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body1">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body1">
										USD 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>
									<img src={usaflag} style={{ width: '80%' }} alt="usa" />
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										USD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										USD 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>
									<img src={canadaFlag} style={{ width: '80%' }} alt="canada" />
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										CAD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										CAD 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										CAD 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>
									<img src={ausFlag} style={{ width: '80%' }} alt="austailia" />
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										AUS 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										AUS 21,000
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										AUS 21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={1}>

								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										Total&nbsp;withdrawal
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										Avg.&nbsp;withdrawal&nbsp;Mth
									</Typography>
								</Grid>
								<Grid item md={3}>
									<Typography variant="body2">
										withdrawal Allocation
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
								<Grid item md={1}>

								</Grid>
								<Grid item md={3}>
									<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
										<Typography variant="body1">
											USD $2,755
										</Typography>
									</Stack>
								</Grid>
								<Grid item md={3}>
									<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
										<Typography variant="body1">
											CAD $0.00
										</Typography>
									</Stack>
								</Grid>
								<Grid item md={3}>
									<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
										<Typography variant="body1">
											AUD $0.00
										</Typography>
									</Stack>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
								<Grid item md={1}>

								</Grid>
								<Grid item md={3} textAlign="center">
									<Typography variant="body3">
										Client withdrawal A/C (USD)
									</Typography>
								</Grid>
								<Grid item md={3} textAlign="center">
									<Typography variant="body3">
										Client withdrawal A/C (CAD)
									</Typography>
								</Grid>
								<Grid item md={3} textAlign="center">
									<Typography variant="body3">
										Client withdrawal A/C (AUD)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</ContainerBox>
				</Grid>
				<Grid item md={6}>
					<Chart
						title="Total Transfers Amount"
						color="#093BA7"
						chartType="column"
					/>
				</Grid>
			</Grid>
			<br></br>
			<Grid container spacing={2}>
				<Grid item md={6} spacing={2}>
					<ContainerBox>
						<Stack>
							<Typography variant="h4" color="primary.Primary_Blue">
								Marketplace Statistics
							</Typography>
							<br></br>
						</Stack>
						<Grid container textAlign="center">
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item md={4}>
									<Typography variant="body1">
										$21,000
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body1">
										$21,000
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body1">
										$21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
								<Grid item md={4}>
									<Typography variant="body2">
										Total Net Sales
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body2">
										Total Returns
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body2">
										Total Expenses
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
								<Grid item md={4}>
									<Typography variant="h2" color="green">
										$21,000
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body1">
										$21,000
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body1">
										$21,000
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
								<Grid item md={4}>
									<Typography variant="body2">
										Total Net Funded
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body2">
										Total Net Receivable
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body2">
										Total VePay Revenue
									</Typography>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
								<Grid item md={4}>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body2">
										$21,000
									</Typography>
								</Grid>
								<Grid item md={4}>
									<Typography variant="body2">
										$21,000
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</ContainerBox>
				</Grid>
				<Grid item md={6}>
					<Chart
						title="Total Transfers Amount"
						color="#093BA7"
						chartType="pie"
					/>
				</Grid>
			</Grid>
			<br></br>
		</div>
	);
}
