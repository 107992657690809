import moment from "moment";

export function getEventDates(value) {
  var eventFrom;
  var eventTo;

  if (value === undefined) {
    eventFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
    eventTo = moment().format("YYYY-MM-DD");
  } else {
    eventFrom = moment(value[0]).format("YYYY-MM-DD");
    eventTo = moment(value[1]).format("YYYY-MM-DD");
  }

  return [eventFrom, eventTo];
}

export function formatCurrency(props) {
  const data = Math.round(props * 100) / 100;

  if (data < 0) {
    const newData = data.toString().replace("-", "");
    return (
      "($" +
      Number(newData)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      ")"
    );
  } else if (data > 0) {
    const newData = data.toString().replace("-", "");
    return (
      "$" +
      Number(newData)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  } else if (data === null || data === 0) {
    return "$0.00";
  } else {
    return "$0.00";
  }
}
