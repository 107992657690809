import * as React from "react";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Checkbox,
  Stack
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledTextBox,StyledSelectTextBox } from "../../../components/TextBox";
import { ContainedSearchButton } from "../../../components/Buttons";
import MenuItem from "@mui/material/MenuItem";

export default function BankInformation() {
  return (
    <div>
      <Accordion expanded>
        <AccordionSummary
        
        sx={{backgroundColor: '#F1F5FC', border: '1px solid #D6D9E1',borderRadius: '8px 8px 0px 0px'}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
           US Bank Saving 1
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledSelectTextBox fullWidth value="BIS 00001"> 
                <MenuItem value="DASHBOARD_ISSUE">US Bank Saving 1</MenuItem>
                <MenuItem value="WIDGET_ISSUE">US Bank Saving 2</MenuItem>
                </StyledSelectTextBox>
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="US Bank" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Number
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="99945457832" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Holder Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="mafaz" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                SWIFT/IBAN Number
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="233423" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Routing Number
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="phoenix " />
              </Grid>
            </Grid>
          </Grid>

          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Type
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="Savings Account" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                Bank Branch Code
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="10000" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank Address
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="123, main street" disabled/>
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                 Bank City
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="New York" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                 Bank State
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="New York" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                Zip Code
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="01"/>
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                Country
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledSelectTextBox fullWidth value="United states">
                <MenuItem value="DASHBOARD_ISSUE">US Bank Saving 1</MenuItem>
                <MenuItem value="WIDGET_ISSUE">US Bank Saving 2</MenuItem>
                </StyledSelectTextBox>
              </Grid>
            </Grid>
            <Grid xs={1} />
            {/* <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Holder Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="mafaz@vepay.com" />
              </Grid>
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <Accordion>
        <AccordionSummary
         sx={{backgroundColor: '#F1F5FC', border: '1px solid #D6D9E1',borderRadius: '8px 8px 0px 0px'}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Add New Bank Account</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Business ID
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="BIS 00001" disabled />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Business Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="Gyber Grils Shopify Store" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Segment
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="Ecommerce" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Email
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="mafaz@vepay.com" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Address
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="112,phoenix ,AZ" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Country
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="United States" />
              </Grid>
            </Grid>
          </Grid>

          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Contact
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="+1 603 456 308" />
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Connected Marketplaces
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="Shopify / Amazon" />
              </Grid>
            </Grid>
          </Grid>
          <br></br>
        </AccordionDetails>
      </Accordion>

      <br></br>
      <Stack direction="row" spacing={1} alignItems="center">
        <Checkbox />
        <Typography variant="body2" color="neutral.letter_Black">
          Notify the changes for user.
        </Typography>
      </Stack>
      <br></br>
      <ContainedSearchButton variant="contained">
        Save changes
      </ContainedSearchButton>
    </div>
  );
}
