import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

export const StyledCheckboxes = styled(Checkbox)(({ theme }) => ({
    borderRadius: 3,
    background: '#FFFFFF',
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        // ? '0 0 0 1px rgb(16 22 26 / 40%)'
        // : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)'
        ,
    backgroundColor: theme.palette.mode === 'dark' 
    ? '#e4ecf2' : '#f5f8fa'
    ,
    
  }));