import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";

export const GetTransfersList = async(value) => {
    const accessToken = sessionStorage.getItem("accessToken");
    
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/admin/accounts/transferList`, {
            method: "GET",
            headers,
           
        });

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetTransfers = async(value) => {
    const accessToken = sessionStorage.getItem("accessToken");
    
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/admin/accounts/transfers/${value}`, {
            method: "GET",
            headers,
           
        });

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetTransferDetails = async(value) => {
    const accessToken = sessionStorage.getItem("accessToken");
    
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/admin/accounts/transferDetail/${value}`, {
            method: "GET",
            headers,
           
        });

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const UpdateTransferDetails = async(transferId, value) => {
    const accessToken = sessionStorage.getItem("accessToken");
    
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/admin/accounts/transfers/${transferId}`, {
            method: "PUT",
            headers,
            body: JSON.stringify(value)
        });

        
        return response
       
    } catch (e) {
        throw e;
    }
}