import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


export const StyledTabs = styled((props) => (
    <Tabs
      {...props}
    />
  ))({
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
  });
  
export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      color: '#868B98',
      background: '#F0F0F0',
      boxShadow: '0px 4px 12px rgba(3, 40, 120, 0.2)',
      borderRadius: '12px 12px 0px 0px',
      '&.Mui-selected': {
        color: '#000',
        background: '#FFFFFF',
        borderRadius: '12px 12px 0px 0px'
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'red',
      },
    }),
  );