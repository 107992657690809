import React from "react";
import { useState, useEffect, useRef } from "react";

import {
	Stack,
	Typography,
	Box,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Grid,
	InputAdornment,
	IconButton,
	Collapse,
	TableContainer,
	MenuItem,
	Snackbar
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StyledSelectTextBox, StyledTextBox } from "../../components/TextBox";
import { StyledTableContainer, StyledTableCell } from "../../components/Table";
import SearchIcon from "@mui/icons-material/Search";
import Moment from "moment";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { YellowBox, GreenBox, RedBox } from "../../components/Chip";
import { GetTransfers, GetTransfersList, GetTransferDetails, UpdateTransferDetails } from "../../services/transfers.services";
import { formatCurrency } from "../../utils/utilityFunctions"
// import { OutlinedButton } from "../../components/Buttons";
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import { TextButton, ContainedButton, OutlinedButton } from "../../components/Buttons";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import './style.css';


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "40%",
	// height: "80%",
	bgcolor: 'background.paper',
	border: '1px solid #FFFFFF',
	// boxShadow: 24,
	p: 4,
	borderRadius: "18px",
	boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
};

function ModalView(props) {
	const [open, setOpen] = useState(false);

	const [transferDetails, setTransferDetails] = useState(null);
	const [snackBarSuccess, setSnackBarSuccess] = useState(false);
	const [snackBarAlert, setSnackBarAlert] = useState(false);
	const [transferStatus, setTransferStatus] = useState(0);
	const [transferNote, setTransferNote] = useState('');
	const [noteError, setNoteError] = useState(false);
	// const hasRunTransferDetails = useRef(false);

	useEffect(() => {
		setOpen(props.status);
		// if (hasRunTransferDetails.current) return;
		// hasRunTransferDetails.current = true;

		const fetchData = async () => {
			try {
				await GetTransferDetails(props.transferId).then((data) => {
					setTransferDetails(data);
				})
			} catch (error) {
				throw error;
			}
		};
		fetchData();

	}, [props]);

	if (!transferDetails) return null;

	const handleClose = () => {
		setTransferDetails(null);
		setTransferStatus(0);
		setOpen(false);
		props.setModalCallback(false);
	};

	const handleUpdate = () => {
		const value = { note: transferNote, status: transferStatus }

		if (transferStatus === 'REJECTED' && transferNote === '') {
			setNoteError(true);
			setSnackBarAlert(true);
			return;
		}

		try {
			UpdateTransferDetails(transferDetails.transactionId, value).then((data) => {
				if (data.ok) {
					setSnackBarSuccess(true);
					setOpen(false);
				} else {
					setSnackBarAlert(true);
				}
			});
		} catch (error) {
			setSnackBarAlert(true);
			throw error;
		}
	}

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={style}>
					<Typography variant="h3" fontWeight="700" align="center" sx={{ pb: 2 }}>Transfer Request</Typography>
					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Transaction&nbsp;ID
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2">{transferDetails.transactionId}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Request&nbsp;Date
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2">{Moment(transferDetails.requestDate).format("MMM D, y")}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Request&nbsp;Time
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{Moment(transferDetails.requestTime, "HH:mm:ss").format("h:mm A")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Client&nbsp;ID
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{transferDetails.clientId}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Client&nbsp;Name
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{transferDetails.clientName}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Transfer&nbsp;Amount
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{formatCurrency(transferDetails.amount)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Transfer&nbsp;Method
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{transferDetails.transferMethod}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Client&nbsp;Bank
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{transferDetails.bankName}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Client&nbsp;Account&nbsp;Number
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<Typography variant="body2" color="neutral.letter_Black">
									{transferDetails.accountNumber}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					{/* <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
					<Grid xs={8} container direction="row" alignItems="center">
						<Grid xs={5}>
							<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
								Vepay&nbsp;Account&nbsp;Number
							</Typography>
						</Grid>
						<Grid xs={2} />
						<Grid xs={5}>
							<Typography variant="body2" color="neutral.letter_Black">
								2345-3242-4242-2344
							</Typography>
						</Grid>
					</Grid>
				</Grid> */}

					{/* <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
					<Grid xs={8} container direction="row" alignItems="center">
						<Grid xs={5}>
							<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
								Reviewed&nbsp;By
							</Typography>
						</Grid>
						<Grid xs={2} />
						<Grid xs={5}>
							<Stack direction="row" spacing={2} alignItems="center">
								<Avatar sx={{ bgcolor: "#093BA7", width: 28, height: 28 }} /> <Typography variant="body2" color="neutral.letter_Black">Mafaz J</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Grid> */}

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Status
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<StyledSelectTextBox
									sx={{ width: "17.4375em" }}
									value={transferStatus}
									onChange={(e) => setTransferStatus(e.target.value)}
								>
									<MenuItem value={0} disabled><em>Select a status</em></MenuItem>
									<MenuItem value='REJECTED'>Reject</MenuItem>
									<MenuItem value='APPROVED'>Approve</MenuItem>
								</StyledSelectTextBox>
							</Grid>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-start" sx={{ p: 1 }}>
						<Grid xs={8} container direction="row" alignItems="center">
							<Grid xs={5}>
								<Typography variant="body1" fontWeight="700" color="neutral.letter_Black">
									Note&nbsp;to&nbsp;Client
								</Typography>
							</Grid>
							<Grid xs={2} />
							<Grid xs={5}>
								<StyledTextBox
									error={noteError}
									sx={{ width: "17.4375em" }}
									value={transferNote}
									onChange={(e) => {
										setNoteError(false);
										setTransferNote(e.target.value)
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container justifyContent="center" sx={{ pt: 3, gap: 1 }}>
						<ContainedButton
							variant="contained"
							onClick={() => handleUpdate()}
							disabled={transferStatus === 0 ? true : false}
						>Confirm</ContainedButton>
						<OutlinedButton variant="outlined" onClick={() => handleClose()}>Close</OutlinedButton>
					</Grid>
				</Box>
			</Modal>
			<Snackbar
				open={snackBarSuccess}
				onClose={() => setSnackBarSuccess(false)}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{ top: "0 !important", left: "0 !important" }}
				autoHideDuration={6000}
			>
				<Box
					onClose={() => setSnackBarSuccess(false)}
					sx={{ width: "100vw", background: "#00AD47", p: 0.5 }}
				>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							width: "100%",
							textAlign: "center",
							alignItems: "center",
							justifyContent: "center",
							color: "#FFFFFF",
						}}
					>
						<CheckCircleOutlineOutlinedIcon fontSize="small" />{" "}
						<Typography variant="body2">
							Status updated!
						</Typography>
					</Stack>
				</Box>
			</Snackbar>

			<Snackbar
				open={snackBarAlert}
				onClose={() => setSnackBarAlert(false)}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{ top: "0 !important", left: "0 !important" }}
				autoHideDuration={6000}
			>
				<Box
					onClose={() => setSnackBarAlert(false)}
					sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
				>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							width: "100%",
							textAlign: "center",
							alignItems: "center",
							justifyContent: "center",
							color: "#FFFFFF",
						}}
					>
						<CancelOutlinedIcon fontSize="small" />{" "}
						<Typography variant="body2">
							{transferNote === '' ? 'Note to Client cannot be an empty field.' : 'There was an error updating please try again.'}
						</Typography>
					</Stack>
				</Box>
			</Snackbar>
		</>
	)
}

function TableData(props) {
	const [data, setData] = useState(props.data);
	const [expandTable, setExpandTable] = useState(props.open);
	const [openModal, setOpenModal] = useState(false);
	const [transfers, setTransfers] = useState(false);
	const [transferId, setTransferId] = useState(null);
	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) return;
		hasRun.current = true;

		const fetchData = async () => {
			try {
				await GetTransfers(data.transferRequestDate).then((data) => {
					setTransfers(data);
				})
			} catch (error) {
				throw error;
			}
		};
		fetchData();
	}, [data.transferRequestDate]);

	useEffect(() => {
		setData(props.data);
		setExpandTable(props.open);
		// eslint-disable-next-line
	}, [props.transfersList, props.open]);

	if (!transfers) return null;

	const handleClickOpen = (value) => {
		setTransferId(value)
		setOpenModal(true);
	};

	const setModalCallback = (data) => {
		setOpenModal(data);
	};

	return (
		<>
			<TableBody>
				<TableRow>
					<TableCell
						align="left"
						variant="body2"
						sx={{ borderLeft: "4px solid #093BA7" }}
					>
						{Moment(data.transferRequestDate).format("MMM D, y")}
					</TableCell>
					<TableCell
						align="left"
						variant="body2"
						sx={{ color: "#093BA7" }}
					>
						Daily Support Request Details
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setExpandTable(!expandTable)}
							variant="text"
						>
							{expandTable ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}

						</IconButton>
					</TableCell>
					<TableCell align="right" variant="body2">
						{data.totalRequestCount}
					</TableCell>
					<TableCell align="right" variant="body2">
						{data.totalPendingCount}
					</TableCell>
					<TableCell align="right" variant="body2">
						{formatCurrency(data.totalRequested)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ padding: 0 }} colSpan={5}>
						<Collapse in={expandTable} timeout="auto" unmountOnExit>
							<TableContainer>
								<Table
									sx={{ minWidth: 650 }}
									aria-label="simple table"
								>
									<TableHead>
										<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
											<StyledTableCell align="left">
												Request Time
											</StyledTableCell>
											<StyledTableCell align="left">
												Transfer ID
											</StyledTableCell>
											<StyledTableCell align="left">
												Client ID
											</StyledTableCell>
											<StyledTableCell align="left">
												Client Name
											</StyledTableCell>
											<StyledTableCell align="left">
												Request Amount
											</StyledTableCell>
											<StyledTableCell align="left">
												Status
											</StyledTableCell>
											<StyledTableCell align="left">
												Action
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{transfers.map((data, index) => (
											<React.Fragment key={index}>
												<TableRow>
													<TableCell align="left" variant="body2">
														{Moment(data.requestTime, "HH:mm:ss").format("h:mm A")}
													</TableCell>
													<TableCell align="left" variant="body2">
														{data.id}
													</TableCell>
													<TableCell align="left" variant="body2">
														{data.clientId}
													</TableCell>
													<TableCell align="left" variant="body2">
														{data.clientName}
													</TableCell>
													<TableCell align="left" variant="body2">
														{formatCurrency(data.amount)}
													</TableCell>
													<TableCell align="left" variant="body2">
														{(() => {
															if (data.status === "APPROVED") {
																return (
																	<GreenBox>
																		<b>Approved</b>
																	</GreenBox>
																);
															} else if (data.status === "REJECTED") {
																return (
																	<RedBox>
																		<b>Rejected</b>
																	</RedBox>
																);
															} else {
																return (
																	<YellowBox>
																		<b>Pending</b>
																	</YellowBox>
																);
															}
														})()}
													</TableCell>
													<TableCell
														align="left"
														variant="body2"
														sx={{ color: "#093BA7" }}
													>
														<TextButton
															onClick={() => handleClickOpen(data.id)}
															disabled={data.status !== "PENDING" ? true : false}
														>
															View
														</TextButton>
													</TableCell>
												</TableRow>


											</React.Fragment>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Collapse>
					</TableCell>
				</TableRow>
			</TableBody>
			{transferId && (<ModalView transferId={transferId} status={openModal} setModalCallback={setModalCallback} />)}
		</>
	)
}

export default function PendingRequests() {
	const [startDate, setStartDate] = useState(
		Moment().subtract(7, "days").toDate()
	);
	const [endDate, setEndDate] = useState(Moment().toDate());

	const [transfersList, setTransfersList] = useState(null);
	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) return;
		hasRun.current = true;

		const fetchData = async () => {
			try {
				// await GetTransfersCountData().then((data) => {
				//   console.log(data);
				// })

				await GetTransfersList().then((data) => {
					setTransfersList(data);
				});
			} catch (error) {
				throw error;
			}
		};
		fetchData();
	}, []);

	if (!transfersList) return null;

	return (
		<>
			<Box
				sx={{
					background: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
					borderRadius: "0px 18px 18px 18px",
					p: 5,
				}}
			>
				<Stack spacing={2} direction="row">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Stack spacing={2}>
							<Typography variant="body2">Start Date</Typography>
							<DesktopDatePicker
								inputFormat="MM/DD/YYYY"
								value={startDate}
								onChange={(newValue) => {
									setStartDate(newValue);
								}}
								renderInput={(params) => (
									<StyledTextBox {...params} size="small" />
								)}
							/>
						</Stack>
						<Stack spacing={2}>
							<Typography variant="body2">End Date</Typography>
							<DesktopDatePicker
								inputFormat="MM/DD/YYYY"
								value={endDate}
								onChange={(newValue) => {
									setEndDate(newValue);
								}}
								renderInput={(params) => (
									<StyledTextBox {...params} size="small" />
								)}
							/>
						</Stack>
					</LocalizationProvider>
				</Stack>
				<br></br>

				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item md={4}>
						<Box
							sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 3 }}
							alignItems="center"
							height="7.5em"
						>
							<Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
								Total Transfer Request Amount
							</Typography>
							<Typography
								variant="h1"
								color="primary.Primary_Blue"
								textAlign="center"
							>
								$0.00
							</Typography>
						</Box>
					</Grid>
					<Grid item md={4}>
						<Box
							sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 3 }}
							height="7.5em"
						>
							<Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
								Total Transfers to Clients
							</Typography>
							<Typography
								variant="h1"
								color="primary.Primary_Blue"
								textAlign="center"
							>
								$0.00
							</Typography>
						</Box>
					</Grid>
					<Grid item md={4}>
						<Box
							sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", p: 3 }}
							height="7.5em"
						>
							<Typography variant="body1" textAlign="center" sx={{ pb: 1 }}>
								Total Pending Transfers
							</Typography>
							<Typography
								variant="h1"
								color="warning.Warning_Dk"
								textAlign="center"
							>
								00
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<br></br>
			<Box
				sx={{
					background: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
					borderRadius: "18px",
					p: 5,
				}}
			>
				<Stack spacing={2} direction="row">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Stack spacing={2}>
							<Typography variant="body2">Start Date</Typography>
							<DesktopDatePicker
								inputFormat="MM/DD/YYYY"
								value={startDate}
								onChange={(newValue) => {
									setStartDate(newValue);
								}}
								renderInput={(params) => (
									<StyledTextBox {...params} size="small" />
								)}
							/>
						</Stack>
						<Stack spacing={2}>
							<Typography variant="body2">End Date</Typography>
							<DesktopDatePicker
								inputFormat="MM/DD/YYYY"
								value={endDate}
								onChange={(newValue) => {
									setEndDate(newValue);
								}}
								renderInput={(params) => (
									<StyledTextBox {...params} size="small" />
								)}
							/>
						</Stack>
					</LocalizationProvider>

					<Stack spacing={2}>
						<Typography variant="body2">Search Record</Typography>
						<StyledTextBox
							sx={{ width: "150%" }}
							fullWidth
							size="small"
							placeholder="Client ID / Clientname / Transfer ID"
							endadornment={
								<InputAdornment position="start">
									<IconButton
										aria-label="toggle password visibility"
										edge="start"
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</Stack>
				</Stack>

				<br></br>
				<StyledTableContainer>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
								<StyledTableCell align="left">
									Transfer Request Date
								</StyledTableCell>
								<StyledTableCell align="left"></StyledTableCell>
								<StyledTableCell align="left">
									Total Request Count
								</StyledTableCell>
								<StyledTableCell align="left">
									Total Pending Request
								</StyledTableCell>
								<StyledTableCell align="left">
									Total Requested Amount
								</StyledTableCell>
							</TableRow>
						</TableHead>

						{transfersList.length <= 0 ? (<TableBody>
							<TableRow>
								<TableCell
									colSpan={9}
									align="center"
									sx={{ padding: "10%" }}
								>
									<Typography variant="h5">
										<b>No results found</b>
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
						) : (transfersList
							.sort((a, b) => parseFloat(Moment(b.transferRequestDate).format("YMMDD")) - parseFloat(Moment(a.transferRequestDate).format("YMMDD")))
							.map((row, index) => (
								<>
									<TableData
										key={index}
										data={row}
										index={index}
										transfersList={transfersList}
									/>
								</>
							))
						)}


					</Table>
				</StyledTableContainer>
			</Box>
		</>
	);
}
