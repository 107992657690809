import { BASE_URL } from "../utils/constants";

export const PostBankInformation = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken');
    try {
        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        let response = await fetch(`${BASE_URL}/trans/seller-banks`,{
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        return response

    } catch (error) {
        throw error
    }

}
