import React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableCell,
  Stack,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  MenuItem
} from "@mui/material";
import { GetClientDetails } from "../../services/client.services";
import { StyledTableCell, StyledTableContainer } from "../../components/Table";
import { TextButton } from "../../components/Buttons";
import { NavLink } from "react-router-dom";
import { StyledSelectTextBox } from "../../components/TextBox";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingBox } from "../../components/Box";

export default function OnboardingRequests() {
  const [clientData, setClientData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        await GetClientDetails().then((data) => {
          setIsLoading(true);
          setClientData(data);
        });
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  if (!clientData) return (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
        borderRadius: "18px",
      }}
      pl={5}
      pr={5}
      pb={5}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        pt={1}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={clientData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Typography variant="body2" color="neutral.Gray_Dark">
            Sort by:
          </Typography>
          <StyledSelectTextBox value="Username" select>
            <MenuItem value="Username">Username</MenuItem>
          </StyledSelectTextBox>
        </Stack>
      </Stack>
      <StyledTableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
              <StyledTableCell align="left">Username</StyledTableCell>
              <StyledTableCell align="left">User ID</StyledTableCell>
              <StyledTableCell align="left">Business ID</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Contact</StyledTableCell>
              <StyledTableCell align="left">Since Boarding</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow>
                  <TableCell align="left" variant="body2">
                    {row.username}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    {row.uniqueId}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    234524
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    {row.email}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    {row.contactNo}
                  </TableCell>
                  <TableCell align="left" variant="body2">
                    1y 2 mo
                  </TableCell>
                  <TableCell align="center">
                    <NavLink
                      to="/client-admin/client-information/edit-information"
                      state={{
                        userId: row.encryptedId,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <TextButton variant="text">Edit</TextButton>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
}
