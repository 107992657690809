import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";

export const RequestAccessToken = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/auth/openid-connect/seller-token`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        let result = handler(response)

        if(response.ok){
            await result.then((data) => {
                sessionStorage.setItem("accessToken", data.access_token)
            });

            sessionStorage.setItem("userName", value.username);

            const accessToken = sessionStorage.getItem("accessToken");
            const userName = sessionStorage.getItem("userName");

            if(accessToken !==null || userName !== null) {
                return response;
            }
        } else {
            return response;
        }

    } catch (e) {
        throw e;
    }
}